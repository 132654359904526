<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>日志</el-breadcrumb-item>
        </el-breadcrumb>        
        <el-card class="result-table" shadow="never">
            <!-- 列表 -->
            <el-table :data="logList.slice((currentPage-1)*pageSize,currentPage*pageSize)"  border stripe>
                <el-table-column prop="trackOperationName" label="操作"></el-table-column>
                <el-table-column prop="trackTime" label="操作时间"></el-table-column>
                <el-table-column prop="userName" label="操作人"></el-table-column>
                <el-table-column prop="tableName" label="操作对应数据"></el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :hide-on-single-page="true"
      :total="logList.length">   
            </el-pagination>
        </el-card>
    </div>
</template>


<script>
  export default {
    data() {
      return {
          logList: [],
          currentPage: 1,
          pageSize: 10,
          total: 0
        }
    },
    created(){
        this.GetLog()
    },
        methods: {
            handleSizeChange: function (size) {
                this.pageSize = size;
            },
            handleCurrentChange: function (currentPage) {
                this.currentPage = currentPage;
            },
            async GetLog() {
                const { result: res } = await this.$http.fetchData('Log/GetLog')
                this.logList = res
                if (this.logList.length == 0) {
                    return this.$message.warning("没有对应条件的日志数据");
                }
            }
        }
  }
</script>






<style lang="less" scoped>
    .el-switch {
        margin-top: 9px;
    }
    .el-pagination {
        text-align:center;
    }
</style>