<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>预算预测对比表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form>
        <el-row>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>品牌</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.brand" filterable placeholder>
                    <el-option
                      v-for="item in brandDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.productName" filterable placeholder>
                    <el-option
                      v-for="item in productNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品线</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.productLine" filterable placeholder>
                    <el-option
                      v-for="item in productLineDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品代码</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.agicodeId" filterable placeholder>
                    <el-option
                      v-for="item in agicodeDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>大区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select
                    v-model="searchList.areaId"
                    filterable
                    placeholder
                    @change="areaChange"
                  >
                    <el-option
                      v-for="item in areaDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>小区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select
                    v-model="searchList.salegroupId"
                    filterable
                    placeholder
                    @change="handleSelectChange"
                  >
                    <el-option
                      v-for="item in saleGroupDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>月份</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.month" filterable placeholder>
                    <el-option
                      v-for="item in monthDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>年份</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select
                    v-model="searchList.year"
                    filterable
                    placeholder
                    @change="handleSelectChange"
                  >
                    <el-option
                      v-for="item in yearDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" @click="getReport(true)">搜索</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" @click="resetSeach">重置</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button
                    :type="listTotalNum>5000? 'disabled':'primary'"
                    :disabled="listTotalNum>5000"
                    @click="exportReport(false)"
                  >实时导出</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" @click="exportReport(true)">邮件通知导出</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>

    <el-card class="result-table" shadow="never" v-show="tableShow">
      <el-table :data="reportList" border stripe height="600">
        <el-table-column fixed prop="productBrand" label="品牌"></el-table-column>
        <el-table-column fixed prop="productSize" label="规格"></el-table-column>
        <el-table-column fixed prop="productName" label="产品"></el-table-column>
        <el-table-column prop="productLine" label="产品线"></el-table-column>
        <el-table-column
          fixed
          v-for="item in agiIteratorList"
          :key="item"
          :label="'产品代码'+ String.fromCharCode(64+item)"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.agicodeList[item-1]? scope.row.agicodeList[item-1].agicodeNo:""}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="大区"></el-table-column>
        <el-table-column prop="saleGroupName" label="小区"></el-table-column>
        <el-table-column prop="year" label="年份"></el-table-column>
        <el-table-column prop="month" label="月份"></el-table-column>
        <el-table-column :formatter="keepDigitals" label="预测净价格" v-if="forecastnetPriceShow" key="forecastShow">
            <template v-slot="scope">
              {{scope.row.forecastNetPrice|thousandth}}
            </template>            
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预算净价格" v-if="budgetnetPriceShow" key="budgetShow">
            <template v-slot="scope">
              {{scope.row.budgetNetPrice|thousandth}}
            </template>            
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预测销量">
            <template v-slot="scope">
              {{scope.row.forecastSales|thousandth}}
            </template>                     
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预算销量">
            <template v-slot="scope">
              {{scope.row.budgetSales|thousandth}}
            </template>           
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预测净销售">
            <template v-slot="scope">
              {{scope.row.forecastNetSales|thousandth}}
            </template>              
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预算净销售">
            <template v-slot="scope">
              {{scope.row.budgetNetSales|thousandth}}
            </template>            
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预测利润">
            <template v-slot="scope">
              {{scope.row.forecastProfit|thousandth}}
            </template>            
        </el-table-column>
        <el-table-column :formatter="keepDigitals" label="预算利润">
            <template v-slot="scope">
              {{scope.row.budgetProfit|thousandth}}
            </template>               
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :hide-on-single-page="true"
        :total="listTotalNum"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                searchList: {},
                queryInfo: {
                    query: "",
                    pagenum: 1,
                    pagesize: 10
                },
                brandDDL: [],
                productNameDDL: [],
                productLineDDL: [],
                agicodeDDL: [],
                areaDDL: [],
                saleGroupDDL: [],
                monthDDL: [],
                yearDDL: [],
                reportList: [],
                listTotalNum: 0,
                agiListMaxLength: 0,
                agiIteratorList: [],
                tableShow: false,
                downloadFileName: null,
                currentYear: null,
                forecastnetPriceShow: true,
                budgetnetPriceShow: true
            };
        },
        created() {
            this.getDDL();
            this.getCurrentDate();
            this.getLoginUserInfo();
        },
        methods: {
            keepDigitals(row, column) {
                if (isNaN(row[column.property])) return row[column.property];
                else return (Math.round(row[column.property] * 100) / 100).toFixed(2);
            },
            async getDDL() {
                const { result: brandList } = await this.$http.get(
                    "/Home/GetSelectList?lookup=brand"
                );
                const { result: productName } = await this.$http.get(
                    "/Home/GetSelectList?lookup=productName"
                );
                const { result: productLine } = await this.$http.get(
                    "/Home/GetSelectList?lookup=productLine"
                );
                const { result: agicode } = await this.$http.get(
                    "/Home/GetSelectList?lookup=agicode"
                );
                const { result: area } = await this.$http.get(
                    "/Home/GetSelectList?lookup=userArea"
                );
                const { result: saleGroup } = await this.$http.get(
                    "/Home/GetSelectList?lookup=salgegroup"
                );
                const { result: month } = await this.$http.get(
                    "/Home/GetSelectList?lookup=NormalMonth"
                );
                const { result: year } = await this.$http.get(
                    "/Home/GetSelectList?lookup=NormalYear"
                );
                this.brandDDL = brandList;
                this.productNameDDL = productName;
                this.productLineDDL = productLine;
                this.agicodeDDL = agicode;
                this.areaDDL = area;
                this.saleGroupDDL = saleGroup;
                this.monthDDL = month;
                this.yearDDL = year;
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
                this.getReport(false);
            },
            handleCurrentChange(newPage) {
                this.queryInfo.pagenum = newPage;
                this.getReport(false);
            },
            async getReport(fromSearch) {
                if (fromSearch) {
                    this.queryInfo.pagenum = 1;
                }
                this.tableShow = false;
                this.reportList = [];
                const { result: res } = await this.$http.fetchData(
                    "Forecast/GetForecastVSBudgetReport",
                    {
                        productBrand: this.searchList.brand,
                        productName: this.searchList.productName,
                        productLine: this.searchList.productLine,
                        agicodeId: !this.searchList.agicodeId
                            ? 0
                            : +this.searchList.agicodeId,
                        areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
                        saleGroupId: !this.searchList.salegroupId
                            ? 0
                            : +this.searchList.salegroupId,
                        month: this.searchList.month,
                        year: this.searchList.year,
                        pageNumber: this.queryInfo.pagenum - 1,
                        pageSize: this.queryInfo.pagesize,
                        isExport: false
                    }
                );
                if (!!res.data.length) {
                    res.data.forEach(e => {
                        e.agicodeList = [];
                    });

                    res.data.forEach(e => {
                        let filterAgi = res.agiCodes.filter(
                            item => item.productId == e.productId
                        );
                        for (let i = 0; i < filterAgi.length; i++) {
                            e.agicodeList.push({ agicodeNo: filterAgi[i].agicodeNo });
                        }
                    });

                    res.data.forEach(e => {
                        e.month = this.monthDDL.find(i => i.value == e.month).label
                    }
                    );
                    this.agiListMaxLength = res.agiCodeMaxLength;
                    this.agiIteratorList = [];
                    for (let i = 0; i < this.agiListMaxLength; i++) {
                        this.agiIteratorList.push(i + 1);
                    }
                    this.reportList = res.data;
                    this.listTotalNum = res.total;
                    this.tableShow = true;
                } else {
                    this.tableShow = false;
                    this.$message.warning("没有对应的搜索数据");
                }
            },
            async resetSeach() {
                this.searchList = {};
                this.searchList.year = this.currentYear;
                const { result: saleGroup } = await this.$http.get(
                    "/Home/GetSelectList?lookup=salgegroup"
                );
                this.saleGroupDDL = saleGroup;
            },
            async exportReport(flag) {
                const { result: fileName } = await this.$http.fetchData(
                    "Forecast/GetForecastVSBudgetReport",
                    {
                        productBrand: this.searchList.brand,
                        productName: this.searchList.productName,
                        productLine: this.searchList.productLine,
                        agicodeId: !this.searchList.agicodeId
                            ? 0
                            : +this.searchList.agicodeId,
                        areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
                        saleGroupId: !this.searchList.salegroupId
                            ? 0
                            : +this.searchList.salegroupId,
                        month: this.searchList.month,
                        year: this.searchList.year,
                        pageNumber: this.queryInfo.pagenum - 1,
                        pageSize: this.queryInfo.pagesize,
                        isExport: !flag,
                        isStored: flag
                    }
                );
                if (fileName == "success") {
                    return this.$message.success(
                        "导出请求已被记录，后面会以邮件形式通知下载。"
                    );
                } else {
                    this.downloadFileName = fileName;
                    var todayDate = new Date();
                    const res = await this.$http.downLoad(
                        `/Home/Download?fileName=${this.downloadFileName}`
                    );
                    var blob = new Blob([res.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    var objectUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.setAttribute("style", "display:none");
                    a.setAttribute("href", objectUrl);
                    a.setAttribute(
                        "download",
                        `预算预测对比表_${todayDate.getFullYear()}${todayDate.getMonth() +
                        1}${todayDate.getDate()}.xlsx`
                    );
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                }
            },
            getCurrentDate() {
                let date = new Date();
                this.currentYear = date.getFullYear(); //获取完整的年份(4位)
                this.searchList.year = this.currentYear;
            },
            handleSelectChange() {
                this.$forceUpdate();
            },
            async areaChange() {
                if (!!this.searchList.areaId) {
                    this.searchList.salegroupId = null;
                    const { result: sglist } = await this.$http.get(
                        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchList.areaId}`
                    );
                    this.saleGroupDDL = sglist;
                }
            },
            async getLoginUserInfo() {
                const { result: res } = await this.$http.get("Home/GetUserInfo");
                if (!!res[0].loginId) {
                    var userRoleIds = res[0].roleIds.split(";");
                    var currentMenuId = window.sessionStorage.getItem("activeMenuId");
                    if (userRoleIds.findIndex(e => e == 3) > -1) {
                        //大区市场
                        this.isLoginUserAsAreaMarket = true;
                        this.forecastnetPriceShow = false;
                        this.budgetnetPriceShow = false;
                    }
                }
            }
        }
    };
</script>