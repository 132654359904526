<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作流配置</el-breadcrumb-item>
        </el-breadcrumb>
        <div v-for="(item,index) in step" :key="item.index">
        <el-card class="search-item" shadow="never">
           <el-row>
               <el-col :span="21">第{{index+1}}步</el-col>
               <el-col  :span="3"><el-button class="addWorkFlowBtn" @click="addWorkFlow(item.workflowStepId)"><span >+</span></el-button></el-col>
           </el-row>
        </el-card>
        <el-card class="search-form" shadow="never" v-for="(workFlow,index) in  item.workflowlist" :key="index">
           <el-row>
             <el-col :span="1">
               <span>字段</span>
             </el-col>
             <el-col :span="3">
                <el-select v-model="workFlow.workflowWfield_id" filterable placeholder="">
                <el-option
                v-for="item in workflowWfieldDDL"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
                </el-select>       
             </el-col>            
             <el-col :span="3">
                <el-select v-model="workFlow.workflowOperationId" filterable  placeholder="">
                 <el-option
                  v-for="item in operationDDL"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option> 
                </el-select>
             </el-col>
             <el-col :span="0.5">
                <span>值</span>
             </el-col>
             <el-col :span="3">
                <!-- <el-input v-model="workFlow.workflowCompareFinalValue"></el-input> -->
                <el-autocomplete
                 v-model="workFlow.workflowCompareFinalValue"
                :fetch-suggestions="workflowCompareListSearch"
                placeholder=""
                ></el-autocomplete>
             </el-col>
             <el-col :span="1">
                <span>审批人</span>
             </el-col>
             <el-col :span="3">
                 <el-select v-model="workFlow.approverRoleIDList[0].role_id"  filterable  placeholder="">
                 <el-option
                  v-for="item in roleDDL"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option> 
                 </el-select>
             </el-col>
             <el-col :span="1">
                <span>审批人</span>
             </el-col>
             <el-col :span="3">
                 <el-select v-model="workFlow.approverRoleIDList[1].role_id"  filterable   placeholder="">
                 <el-option
                  v-for="item in roleDDL"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option> 
                </el-select>
             </el-col>
             <el-col :span="1" :offset="3">
                 <el-button  class="removeWorkFlowBtn" @click="removeWorkFlow(workFlow.workflowStepId,workFlow.workflowId,workFlow.newAddIndex)"><span >移除</span></el-button>
             </el-col>

           </el-row>
        </el-card>
        </div>
        <el-card class="search-form" shadow="never" >
            <el-button class="btn" type="primary" @click= "addStep"  >增加审批步骤</el-button>
            <el-button class="btn" type="primary" @click= "saveStep"  >保存</el-button>
        </el-card>

    </div>


</template>
<script>
export default {
    data(){

    return{
        roleDDL:[],
        operationDDL:[],
        workflowWfieldDDL:[],
        step:[],
        workflowWfieldDDLForAuto:[]
        }
    },
    created(){
       this.getDDL()
       this.gerWorkFlow()
    },
    methods:{
       async getDDL(){
           const {result:rolelist} = await this.$http.get('/Home/GetSelectList?lookup=userRole')
           const {result:operationlist} = await this.$http.get('/Home/GetSelectList?lookup=operation')
           const {result:wfField} = await this.$http.get('/Home/GetSelectList?lookup=workflowfield')
           const {result:wfFieldforAuto} = await this.$http.get('/Home/GetSelectList?lookup=workflowfieldAuto')
           rolelist.forEach(e=>e.value = +(e.value))
           rolelist.push({"value":0,"label":' '})
           operationlist.forEach(e=>e.value = +(e.value))
           wfField.forEach(e=>e.value = +(e.value))
           this.roleDDL = rolelist
           this.operationDDL = operationlist
           this.workflowWfieldDDL = wfField
           this.workflowWfieldDDLAuto = wfFieldforAuto
       },
       async gerWorkFlow()
       {
            const {result:step} = await this.$http.fetchData('Workflow/GetWorkflowSteps')
            step.forEach(e=>e.workflowlist.forEach(item=>{
                for(let i = 0;i<2;i++)
                { 
                    if(item.approverRoleIDList.length<2)
                    item.approverRoleIDList.push({"approver_id":0,"role_id":0,"wapproverApproveOrder":0})
                }
            }))
            step.forEach(e=>e.workflowlist.forEach(item=>{
                 if(item.workflowComparefieldTypeIsValue==0)
                   {
                      item.workflowCompareFinalValue = item.workflowCompareWfieldName.trim()
                   }
                 else{
                      item.workflowCompareFinalValue = item.workflowCompareValue.toString()
                 }

            }))
            this.step = step
            console.log(step)
       },
       addWorkFlow(stepId)
       {
            this.step.find(e=>{
                
                if(e.workflowStepId==stepId)
            {
                let index = e.workflowlist.length
                e.workflowlist.push(
             {
                "workflowId": 0,
                "workflowStepId": stepId,
                "workflowWfield_id": 1,
                "workflowWfieldName": "",
                "workflowOperationId": 1,
                "workflowOperationName": null,
                "workflowCompareValue": 0,
                "workflowCompareWfieldId": 0,
                "workflowCompareWfieldName": null,
                "workflowComparefieldTypeIsValue": 0,
                "workflowCompareFinalValue":null,
                "approverRoleIDList": [
                {
                "approver_id": 0,
                "workflow_id": 0,
                "wapproverApproveOrder": 0,
                "role_id": 0,
                "role_Name": ""
                 },
                {
                "approver_id": 0,
                "workflow_id": 0,
                "wapproverApproveOrder": 0,
                "role_id": 0,
                "role_Name": ""
                }
                ],
                "newAddIndex":index
            })
            
            }
            
            })
         
       },
       removeWorkFlow(stepId,workFlowId,newAddIndex)
       {
           var stepIndex = this.step.findIndex(e=>e.workflowStepId == stepId)
           if(!!newAddIndex){this.step[stepIndex].workflowlist.splice(this.step[stepIndex].workflowlist.findIndex(e=>e.newAddIndex == newAddIndex),1)}
           else this.step[stepIndex].workflowlist.splice(this.step[stepIndex].workflowlist.findIndex(e=>e.workflowId == workFlowId),1)
       },
       addStep(){
           var stepLength = this.step.length
           this.step.push(
                 {
                "workflowStepId": stepLength+1,
                "workflowlist": [
                   {
                    "workflowId": 0,
                    "workflowStepId": stepLength+1,
                    "workflowWfield_id": 1,
                    "workflowWfieldName": "",
                    "workflowOperationId": 1,
                    "workflowOperationName": null,
                    "workflowCompareValue": 0,
                    "workflowCompareWfieldId": 0,
                    "workflowCompareWfieldName": null,
                    "workflowComparefieldTypeIsValue": 0,
                    "workflowCompareFinalValue":null,
                    "approverRoleIDList": [
                        {
                        "approver_id": 0,
                        "workflow_id": 0,
                        "wapproverApproveOrder": 0,
                        "role_id": 0,
                        "role_Name": ""
                        },
                        {
                        "approver_id": 0,
                        "workflow_id": 0,
                        "wapproverApproveOrder": 0,
                        "role_id": 0,
                        "role_Name": ""
                        }
                     ]
                    },
                    ]
                },
           )
       },
       async saveStep(){
               this.step.forEach(e=>e.workflowlist.forEach(i=>
                 {
                     if (this.isNumber(i.workflowCompareFinalValue))
                   {
                        i.workflowComparefieldTypeIsValue = 1
                        i.workflowCompareValue = +(i.workflowCompareFinalValue)
                   }
                   else{
                        i.workflowComparefieldTypeIsValue = 0
                        i.workflowCompareWfieldName = i.workflowCompareFinalValue
                        i.workflowCompareWfieldId = this.workflowWfieldDDL.find(item=>item.label.trim()==i.workflowCompareWfieldName).value
                   }

                   i.approverRoleIDList.forEach((s,index)=>s.wapproverApproveOrder=index+1)
                   i.workflowWfieldName=this.workflowWfieldDDL.find(item=>item.value=i.workflowWfield_id).value
                 }
                   ))

                 const {result:res} = await this.$http.fetchData('Workflow/UpdateWorkflowSteps',{workFlowData:this.step})
                 if(res=="success")
                 {
                     this.$message.success("保存成功");
                 }
                 else{
                     this.$message.error("保存失败");
                 }
       },
       isNumber(val)
       {
         var regPos = /^\d+(\.\d+)?$/; //非负浮点数
         var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
         if(regPos.test(val) || regNeg.test(val)){
         return true;
         }
         else{return false; }
        },
        workflowCompareListSearch(queryString, cb){
            var list = this.workflowWfieldDDLAuto;
            var results = queryString ? list.filter(e=>e.label.indexOf(queryString.toString())>-1) : list;
            cb(results);
        }

    
}}
</script>






<style lang="less" scoped>
.addWorkFlowBtn{
    font-size: 22px;
    color:white;
    cursor:pointer;
    background: #D9D5D2;
    border-color:#D9D5D2;
    height:36px;
    position: relative;
    bottom:10px;
}
.addWorkFlowBtn:focus,.addWorkFlowBtn:hover{
    color:#fff;
    border-color:#D9D5D2;
    background-color:#D9D5D2;
}
.removeWorkFlowBtn{
    border-color:transparent !important;
    height:36px;
    position: relative;
    right:20px;
}
.removeWorkFlowBtn:focus,.removeWorkFlowBtn:hover{
    background-color:transparent;
}
.search-item .el-card__body
{
    padding:0 auto !important;
}
.el-row{
    line-height: 36px;
}

</style>