<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>预测销量</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>销售大区</label>
                </el-col>
                <el-col :span="13">
                  <el-select @change="areaChange" v-model="searchList.areaId" filterable placeholder>
                    <el-option
                      v-for="item in AreaDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>销售小区</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.sgId" filterable placeholder @change="handleSelectChange">
                    <el-option
                      v-for="item in SGNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品代码</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.agicodeId" filterable placeholder>
                    <el-option
                      v-for="item in agicodeDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>年份</label>
                </el-col>
                <el-col :span="13">
                  <el-select
                    v-model="searchList.yearId"
                    filterable
                    placeholder
                    @change="handleSelectChange"
                  >
                    <el-option
                      v-for="item in YearDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>月份</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.MonthId" filterable placeholder>
                    <el-option
                      v-for="item in MonthDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <!--<el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>预测销量</label>
                </el-col>
                <el-col :span="13">
                  <el-input v-model="searchList.startSale" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>-->
          <!--<el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="6">
                  <label>至</label>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="searchList.endSale" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>-->
        </el-row>
        <el-row>
          <el-col :offset="14">
            <el-form-item>
              <el-button type="primary" @click="Search">查询</el-button>
              <el-button type="primary" @click="resetSearch">重置</el-button>
              <el-button type="primary" @click="showImportDialog">导入</el-button>
              <el-button type="primary" @click="exportSale">导出</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>

    <el-card class="result-table" shadow="never">
        <el-table :data="importSaleList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                  border
                  stripe>
            <el-table-column prop="areaName" label="销售大区"></el-table-column>
            <el-table-column prop="salegroupName" label="销售小区"></el-table-column>
            <el-table-column prop="agicodeNo" label="产品代码"></el-table-column>
            <el-table-column prop="year" label="年份"></el-table-column>
            <el-table-column prop="monthLabel" label="月份"></el-table-column>
            <el-table-column label="预测销量">
                <template v-slot="scope">
                    {{scope.row.sales | thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="月份净销售">
                <template v-slot="scope">
                    {{scope.row.netSales | thousandth}}
                </template>
            </el-table-column>
        </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :hide-on-single-page="true"
        :total="listTotalNum"
      ></el-pagination>
    </el-card>

    <!-- 导入对话框 -->
    <el-dialog
      title="导入预测数据"
      :visible.sync="importDialogVisible"
      width="40%"
      @close="importDialogClosed"
      :close-on-click-modal='false'
    >
      <el-form :model="importSaleForm" ref="importSaleFormRef" label-width="100px">
        <!--                 <div>
                    <span>请注意：只能导入当前选择年月的预测销量，导入成功后，原有的该年月的销量将被覆盖。</span><br /><br />
                </div>
                <el-form-item label="导入年份">
                    <el-select v-model="importSaleForm.importyearId" placeholder="请选择">
                        <el-option v-for="item in YearDDL"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="导入月份">
                    <el-select v-model="importSaleForm.importmonthId" placeholder="请选择">
                        <el-option v-for="item in MonthDDL"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
        </el-form-item>-->
        <el-form-item label="上传文件：">
          <el-upload
            class="upload"
            ref="upload"
            action
            :file-list="fileList"
            :multiple="false"
            :show-file-list="true"
            :limit="1"
            :auto-upload="false"
            accept=".xlsx"
          >
            <el-button slot="trigger" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传一个文件，需要先删除已选择的文件，然后重新选择</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确定上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      importSaleList: [],
      searchList: {
        areaId: null,
        sgId: null,
        yearId: null,
        MonthId: null,
        agicode: null,
        startSale: null,
        endSale: null
      },
      AreaDDL: [],
      SGNameDDL: [],
      YearDDL: [],
      MonthDDL: [],
      agicodeDDL: [],
      downloadFileName: "",
      importDialogVisible: false,
      importSaleForm: { importyearId: null, importmonthId: null },
      exporturl: "",
      fileList: [],
        uploadUrl: this.$http.baseURL + "/Forecast/ImportForecastSale",
        currentPage: 1,
        pageSize: 50,
          queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10
      },
      listTotalNum: 0,
    };
  },
  created() {
    this.getCurrentDate();
    this.getimportSaleList();
    this.getAllDDL();
  },
   methods: {
      handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getimportSaleList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getimportSaleList();
    },
    async getimportSaleList() {
      const { result: monthList } = await this.$http.get(
            "/Home/GetSelectList?lookup=NormalMonth"
        );
      this.MonthDDL = monthList;

      const { result: res } = await this.$http.fetchData(
        "/Forecast/GetForecastSale",
        {
          areaId: this.searchList.areaId,
          agicodeId: this.searchList.agicodeId,
          salegroupId: this.searchList.sgId,
          year: this.searchList.yearId,
          month: this.searchList.MonthId,
          startSale: this.searchList.startSale,
          endSale: this.searchList.endSale,
          pageNum:this.queryInfo.pagenum - 1,
          pageSize:this.queryInfo.pagesize,
        }
      );
      res.forecastSale.forEach(e=>e.monthLabel = this.MonthDDL.find(i=>i.value ==e.month).label)
      this.importSaleList = res.forecastSale;
      this.listTotalNum = res.listTotalNum;
    },
    async Search() {
      this.queryInfo.pagenum = 1
      const { result: res } = await this.$http.fetchData(
        "/Forecast/GetForecastSale",
        {
          areaId: this.searchList.areaId,
          agicodeId: this.searchList.agicodeId,
          salegroupId: this.searchList.sgId,
          year: this.searchList.yearId,
          month: this.searchList.MonthId,
          startSale: this.searchList.startSale,
          endSale: this.searchList.endSale,
          pageNum:this.queryInfo.pagenum - 1,
          pageSize:this.queryInfo.pagesize,
        }
      );
      res.forecastSale.forEach(e=>e.monthLabel = this.MonthDDL.find(i=>i.value ==e.month).label)
      this.importSaleList = res.forecastSale;
      this.listTotalNum = res.listTotalNum;
    },
    async resetSearch() {
      this.searchList = [];
      this.searchList.yearId = this.currentYear;
      const { result: sgList } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      this.SGNameDDL = sgList;
    },
    async getAllDDL() {
      const { result: areaList } = await this.$http.get(
        "/Home/GetSelectList?lookup=userArea"
      );
      const { result: sgList } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: yearList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      const { result: monthList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      const { result: agicodeList } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicode"
      );
      this.AreaDDL = areaList;
      this.SGNameDDL = sgList;
      this.YearDDL = yearList;
      this.MonthDDL = monthList;
      this.agicodeDDL = agicodeList;
    },
    async showImportDialog() {
      /*      const { result: yearList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      const { result: monthList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      this.YearDDL = yearList;
      this.MonthDDL = monthList; */
      this.importDialogVisible = true;
    },
    async exportSale() {
      const { result: fileName } = await this.$http.fetchData(
        "/Forecast/ExportForecastSale",
        {
          areaId: this.searchList.areaId,
          agicodeId: this.searchList.agicodeId,
          salegroupId: this.searchList.sgId,
          year: this.searchList.yearId,
          month: this.searchList.MonthId,
          startSale: this.searchList.startSale,
          endSale: this.searchList.endSale
        }
      );
      this.downloadFileName = fileName;
      var todayDate = new Date();
      const res = await this.$http.downLoad(
        `/Home/Download?fileName=${this.downloadFileName}`
      );
      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      var objectUrl = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("style", "display:none");
      a.setAttribute("href", objectUrl);
      a.setAttribute(
        "download",
        `预测销量_${todayDate.getFullYear()}${todayDate.getMonth() +
          1}${todayDate.getDate()}.xlsx`
      );
      a.click();
      URL.revokeObjectURL(objectUrl);
    },
    importDialogClosed() {
      this.$refs.importSaleFormRef.resetFields();
    },
    async submitUpload(param) {
      let file = this.$refs.upload.uploadFiles.pop().raw; //这里获取上传的文件对象
      let formData = new FormData();
        formData.append("file", file);
        formData.append("year", this.searchList.yearId);
      const { result: res } = await this.$http.post(
        "/Forecast/ImportForecastSale",
        formData
      );

      if (res == "success") {
        this.getimportSaleList();
        this.importDialogVisible = false;
        return this.$message.success("上传成功");      
      } else if (res == "successMail") {
          return this.$message.success("导入请求已被记录，后面会以邮件形式通知。");
      } else if (!!res.errorFilePath) {  //返回报错文件和报错信息
          this.$notify({
            title: "导入错误",
            message: res.promptMessage,
            type: "warning",
            position: 'bottom-left'
          }); 
        var todayDate = new Date();
        const errorFile = await this.$http.downLoad(
          `/Home/Download?fileName=${res.errorFilePath}`
        );
        var blob = new Blob([errorFile.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute(
          "download",
          `预测销量导入失败_${todayDate.getFullYear()}${todayDate.getMonth() +
            1}${todayDate.getDate()}.xlsx`
        );
        a.click();
        URL.revokeObjectURL(objectUrl);
        } 
      
      else {
          //return this.$message.error("上传失败：" + res);
          this.$message({
              dangerouslyUseHTMLString: true,
              message: res,
              type: 'error'
          });
      }
    },
    getCurrentDate() {
      let date = new Date();
      this.currentYear = date.getFullYear(); //获取完整的年份(4位)
      this.searchList.yearId = this.currentYear;
    },
    handleSelectChange() {
      this.$forceUpdate();
    },
    async areaChange(){
     if(!!this.searchList.areaId)
      {
       this.searchList.sgId = null
       const { result: sglist } = await this.$http.get(
        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchList.areaId}`
       );
      this.SGNameDDL = sglist;
      }
    }
  }
};
</script>






<style lang="less" scoped>
.title {
  color: #aab400;
}

.addBtn {
  margin-top: 17px;
}

.el-switch {
  margin-top: 9px;
}

.el-tag {
  background-color: #aab400;
  color: #fff;
  border-color: none;
}
</style>
