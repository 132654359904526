<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>预算分配表</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card class="search-item" shadow="never">
            <span>搜索条件</span>
        </el-card>

        <el-card class="search-form" shadow="never">
            <!-- 搜索表单 -->
            <el-form>
                <el-row>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>品牌</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.brand" filterable placeholder>
                                        <el-option v-for="item in brandDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>产品</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.productName" filterable placeholder>
                                        <el-option v-for="item in productNameDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>产品线</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.productLine" filterable placeholder>
                                        <el-option v-for="item in productLineDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>产品代码</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.agicodeId" filterable placeholder>
                                        <el-option v-for="item in agicodeDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>大区</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.areaId" filterable placeholder @change="areaChange">
                                        <el-option v-for="item in areaDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>小区</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.salegroupId" filterable placeholder  @change="handleSelectChange">
                                        <el-option v-for="item in saleGroupDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>月份</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.month" filterable placeholder>
                                        <el-option v-for="item in monthDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item>
                                    <label>年份</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.year"
                                               filterable
                                               placeholder
                                               @change="handleSelectChange">
                                        <el-option v-for="item in yearDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="8">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary" @click="getBudget(true)">搜索</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary" @click="resetSearch">重置</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary" v-if="haveEditAccess" @click="editBudget">编辑</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary" v-if="haveEditAccess" @click="saveBudget">保存</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row class="lastBtnRow">
                    <el-col :span="8" :offset="16">
                        <el-row>
                            <!--<el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary"
                                               v-if="haveEditAccess&&!isLocked"
                                               @click="showInitDialog">初始化预算</el-button>
                                </el-form-item>
                            </el-col>-->
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary"
                                               v-if="haveEditAccess&&!isLocked"
                                               @click="showImportDialog('price')">导入价格</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary"
                                               v-if="haveEditAccess&&!isLocked"
                                               @click="showImportDialog('sale')">导入销量</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary"
                                               v-if="haveEditAccess"
                                               @click="showImportDialog('cost')">导入成本</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row class="lastBtnRow">
                    <el-col :span="8" :offset="16">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button :type="listTotalNum>5000? 'disabled':'primary'" :disabled="listTotalNum>5000" @click="exportbudget(false)">实时导出</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary"  @click="exportbudget(true)">邮件通知导出</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-button type="primary" @click="lockUnlock" v-if="lockBtnShow">{{lockBtnName}}</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <el-card class="search-item" shadow="never">
            <span>查询结果</span>
        </el-card>

        <el-card class="result-table" shadow="never">
            <el-table :data="budgetList" border stripe height="600">
                <el-table-column v-for="item in agiIteratorList"
                                 :key="item"
                                 :label="'产品代码'+ String.fromCharCode(64+item)"
                                 :cell-style="{padding:'2px'}">
                    <template slot-scope="scope">
                        <span>{{ scope.row.agicodeList[item-1]? scope.row.agicodeList[item-1].agicodeNo:""}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="budgetBrand" label="品牌"></el-table-column>
                <el-table-column prop="budgetProductSize" label="规格"></el-table-column>
                <el-table-column prop="budgetProductName" label="产品"></el-table-column>
                <el-table-column prop="budgetProductLine" label="产品线"></el-table-column>
                <el-table-column prop label="全国"></el-table-column>
                <el-table-column prop="budgetAreaName" label="大区"></el-table-column>
                <el-table-column prop="budgetSGName" label="小区"></el-table-column>
                <el-table-column prop="budgetYear" label="年份"></el-table-column>
                <el-table-column prop="budgetMonthLabel" label="月份"></el-table-column>
                <el-table-column label="预算基本折扣%" width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetBasicDiscount}}%</span>
                        <el-input v-bind:disabled="inputDisable"
                                  @change="inputChange(scope.row)"
                                  v-if="haveEditAccess"
                                  v-model="scope.row.budgetBasicDiscount"
                                  suffix-icon="iconfont icon-percent"
                                  size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="预算-发票价" width="130px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetBillingPrice|thousandth}}</span>
                        <!--         <el-input
              v-if="haveEditAccess"
              v-bind:disabled="inputDisable"
              @change="inputChange(scope.row)"
              v-model="scope.row.budgetBillingPrice"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetBillingPrice" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="budgetDiscounPercent" label="预算-即时铺货折扣%" width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetDiscounPercent}}%</span>
                        <el-input v-if="haveEditAccess"
                                  v-bind:disabled="inputDisable"
                                  @change="inputChange(scope.row)"
                                  v-model="scope.row.budgetDiscounPercent"
                                  suffix-icon="iconfont icon-percent"
                                  size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column v-for="item in otherDiscountHeader"
                                 :key="item.headerName"
                                 :label="item.headerName"
                                 width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{ scope.row.otherDiscountList[scope.row.otherDiscountList.findIndex(e=>e.header_Id == item.headId)].discount_value}}</span>
                        <span v-show="item.isPercentClass == 'iconfont icon-percent'&&!haveEditAccess">%</span>
                        <el-input v-if="haveEditAccess"
                                  @change="inputChange(scope.row)"
                                  v-bind:disabled="inputDisable"
                                  v-model="scope.row.otherDiscountList[scope.row.otherDiscountList.findIndex(e=>e.header_Id == item.headId)].discount_value"
                                  :suffix-icon="item.isPercentClass"
                                  size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="预算-净价格" width="100px" v-if="netPriceShow" key="netPriceShow">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetNetPrice|thousandth}}</span>
                        <!--             <el-input
              v-bind:disabled=true
              @change="inputChange(scope.row)"
              v-if="haveEditAccess"
              v-model="scope.row.budgetNetPrice"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetNetPrice" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled=true></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预算-大区净价格" width="100px" v-if="netPriceShow" key="budgetAreaAvgNetPrice">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetAreaAvgNetPrice|thousandth}}</span>                       
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetAreaAvgNetPrice" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled=true></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预算-销量" width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetSales|thousandth}}</span>
                        <!--             <el-input
              v-bind:disabled="inputDisable"
              @change="inputChange(scope.row)"
              v-if="haveEditAccess"
              v-model="scope.row.budgetSales"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetSales" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预算-净销售" width="150px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetNetSales|thousandth}}</span>
                        <!--             <el-input
              v-bind:disabled=true
              @change="inputChange(scope.row)"
              v-if="haveEditAccess"
              v-model="scope.row.budgetNetSales"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetNetSales" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled=true></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预算-利润" width="150px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetProfit|thousandth}}</span>
                        <!--             <el-input
              v-bind:disabled= true
              @change="inputChange(scope.row)"
              v-if="haveEditAccess"
              v-model="scope.row.budgetProfit"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetProfit" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled=true></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="成本" width="100px" v-if="costShow" key="cost">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetCost|thousandth}}</span>
                        <!--             <el-input
              v-if="haveEditAccess"
              @change="inputChange(scope.row)"
              v-model="scope.row.budgetCost"
              v-bind:disabled="budgetCostDisable"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetCost" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="budgetCostDisable"></currency-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="上年度铺货折扣%" width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetLastYearDiscount}}%</span>
                        <el-input v-bind:disabled="inputDisable"
                                  v-if="haveEditAccess"
                                  v-model="scope.row.budgetLastYearDiscount"
                                  suffix-icon="iconfont icon-percent"
                                  size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="上年度销量" width="100px">
                    <template slot-scope="scope">
                        <span v-if="!haveEditAccess">{{scope.row.budgetLastYearSales|thousandth}}</span>
                        <!--             <el-input
              v-bind:disabled="inputDisable"
              v-if="haveEditAccess"
              v-model="scope.row.budgetLastYearSales"
              v-currency="{currency:null}"
              size="small"
            ></el-input> -->
                        <div class="el-input--small" v-if="haveEditAccess">
                            <currency-input v-model="scope.row.budgetLastYearSales" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="queryInfo.pagenum"
                           :page-sizes="[10, 20, 50]"
                           :page-size="queryInfo.pagesize"
                           layout="total, sizes, prev, pager, next, jumper"
                           :hide-on-single-page="true"
                           :total="listTotalNum"></el-pagination>
        </el-card>

        <!-- 导入对话框 -->
        <el-dialog :title="importTitile"
                   :visible.sync="importDialogVisible"
                   width="40%"
                   @close="importDialogClosed"
                   :close-on-click-modal='false'>
            <el-form ref="importFormRef" label-width="100px">
                <el-form-item label="上传文件：">
                    <el-upload class="upload"
                               ref="upload"
                               action
                               :file-list="fileList"
                               :multiple="false"
                               :show-file-list="true"
                               :limit="1"
                               :auto-upload="false"
                               accept=".xlsx">
                        <el-button slot="trigger" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传一个文件，需要先删除已选择的文件，然后重新选择</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="importBudget">确定上传</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="importTitile"
                   :visible.sync="InitDialogVisible"
                   width="40%"
                   @close="importDialogClosed"
                   :close-on-click-modal='false'>
            <el-form ref="importFormRef" label-width="100px">
                <el-form-item label="请注意：">
                    <span>
                        初始化预算将删除当前预算，自动生成页面所选年度的预算数据和预测数据，价格，销量，折扣都为0。
                        请慎重考虑是否初始化。
                    </span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="InitDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="initbudget">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
  data() {
    return {
      brandDDL: [],
      productNameDDL: [],
      productLineDDL: [],
      agicodeDDL: [],
      areaDDL: [],
      saleGroupDDL: [],
      monthDDL: [],
      yearDDL: [],
      budgetList: [],
      searchList: {},
      agiIteratorList: [],
      agiListMaxLength: 0,
      otherDiscountHeader: [],
      budgetCostDisable: true,
      inputDisable: true, //exclude cost
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10
      },
      listTotalNum: 0,
      isLoginUserAsAreaMarket: false,
      haveEditAccess: false,
      isLocked: false,
      lockBtnShow: false,
      importTitile: "",
      fileList: [],
        importDialogVisible: false,
        InitDialogVisible: false,
      uploadType: "",
      currentYear: null,
      netPriceShow:true,
        costShow: true,
        budgetAvgNetPriceList: [] //add by lance 20211202 DMS Change
    };
  },
  computed: {
    lockBtnName: function() {
      if (this.isLocked) {
        return "解除预算锁定";
      } else {
        return "锁定预算";
      }
    }
  },
  created() {
    this.getDDL();
    this.getCurrentDate();
    this.getLoginUserInfo();
    this.getBudget(true);
    this.getLock();
  },
  methods: {
    async getDDL() {
      const { result: brandList } = await this.$http.get(
        "/Home/GetSelectList?lookup=brand"
      );
      const { result: productName } = await this.$http.get(
        "/Home/GetSelectList?lookup=productName"
      );
      const { result: productLine } = await this.$http.get(
        "/Home/GetSelectList?lookup=productLine"
      );
      const { result: agicode } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicode"
      );
      const { result: area } = await this.$http.get(
        "/Home/GetSelectList?lookup=userArea"
      );
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: month } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      const { result: year } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      this.brandDDL = brandList;
      this.productNameDDL = productName;
      this.productLineDDL = productLine;
      this.agicodeDDL = agicode;
      this.areaDDL = area;
      this.saleGroupDDL = saleGroup;
      this.monthDDL = month;
      this.yearDDL = year;
    },
    async getBudget(fromSearch) {
      if (fromSearch) {
        this.queryInfo.pagenum = 1;
        }
      const { result: month } = await this.$http.get(
            "/Home/GetSelectList?lookup=NormalMonth"
        );
      this.monthDDL = month;

      const { result: res } = await this.$http.fetchData(
        "Budget/GetBudgetList",
        {
          brand: this.searchList.brand,
          productName: this.searchList.productName,
          productLine: this.searchList.productLine,
          agicodeId: !this.searchList.agicodeId
            ? 0
            : +this.searchList.agicodeId,
          areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
          salegroupId: !this.searchList.salegroupId
            ? 0
            : +this.searchList.salegroupId,
          month: this.searchList.month,
          year: this.searchList.year,
          pageNum: this.queryInfo.pagenum - 1,
          pageSize: this.queryInfo.pagesize
        }
      );
      res.budgetlist.forEach(e => {
        e.isChanged = false
        e.budgetMonthLabel = this.monthDDL.find(i=>i.value==e.budgetMonth).label
      }
      );
      this.budgetList = res.budgetlist;
      this.agiListMaxLength = res.agiMaxLength;
      this.listTotalNum = res.listTotalNum;
      this.agiIteratorList = [];
      for (let i = 0; i < this.agiListMaxLength; i++) {
        this.agiIteratorList.push(i + 1);
      }
      if (this.budgetList.length > 0) {
        this.otherDiscountHeader = [];
        if (this.isLoginUserAsAreaMarket) {
          //如果是大区市场，则去掉不可显示的discount
          this.budgetList[0].otherDiscountList = this.budgetList[0].otherDiscountList.filter(
            e => e.header_Show == 1
          );
        }
        var isPercentClass = "";
        for (let i = 0; i < this.budgetList[0].otherDiscountList.length; i++) {
          if (
            this.budgetList[0].otherDiscountList[i].header_Name.indexOf("%") >
            -1
          ) {
            isPercentClass = "iconfont icon-percent";
          } else {
            isPercentClass = "";
          }
          this.otherDiscountHeader.push({
            headerName: this.budgetList[0].otherDiscountList[i].header_Name,
            isShow:
              this.budgetList[0].otherDiscountList[i].header_Show == 1
                ? true
                : false,
            headId: this.budgetList[0].otherDiscountList[i].header_Id,
            isPercentClass: isPercentClass
          });
            this.otherDiscountHeader.sort((a, b) => a.headId - b.headId);
        }
      }
    },
    async resetSearch() {
      this.searchList = [];
      this.searchList.year = this.currentYear;
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      this.saleGroupDDL = saleGroup;
    },
    editBudget() {
      if (this.isLocked) {
        this.budgetCostDisable = false;
        this.inputDisable = true;
      } else {
        this.budgetCostDisable = false;
        this.inputDisable = false;
      }
    },
    inputChange(row) {
      row.isChanged = true;
      let getSum = (total, num) => total + num;
      let otherDiscountValue = [];
      row.otherDiscountList.forEach(e =>
          otherDiscountValue.push(+e.discount_value)
      );
      let sumOtherDiscount =
            otherDiscountValue.reduce(getSum);
        
        row.budgetNetPrice =
            this.convertDecimal(
                row.budgetBillingPrice * (1 - row.budgetDiscounPercent / 100 - sumOtherDiscount / 100));

        row.budgetProfit =
          this.convertDecimal(
            (row.budgetNetPrice - row.budgetCost) * row.budgetSales); 
        
        row.budgetNetSales =
            this.convertDecimal(row.budgetNetPrice * row.budgetSales);
        //this.reCaculateAreaAvgNetPrice();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getBudget(false);
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getBudget(false);
    },
    async getLoginUserInfo() {
      const { result: res } = await this.$http.get("Home/GetUserInfo");
      if (!!res[0].loginId) {
        var userRoleIds = res[0].roleIds.split(";");
        var currentMenuId = window.sessionStorage.getItem("activeMenuId");
          if (userRoleIds.findIndex(e => e == 1) > -1 ||
              userRoleIds.findIndex(e => e == 2) > -1) {
          this.lockBtnShow = true;
        }
        if (
          userRoleIds.findIndex(e => e == 1) > -1 ||
          userRoleIds.findIndex(e => e == 2) > -1
        ) {
          //系统管理员||价格经理
          this.isLoginUserAsAreaMarket = false;
        } else if (userRoleIds.findIndex(e => e == 3) > -1) {
          //大区市场
          this.isLoginUserAsAreaMarket = true;
          this.netPriceShow = false;
          this.costShow = false;

        }
        if (
          res[0].pageAccess.findIndex(
            e => e.menuId == currentMenuId && e.access == "modify"
          ) > -1
        ) {
          this.haveEditAccess = true;
        }
      }
    },
    async saveBudget() {
      if (this.budgetList.filter(e => e.isChanged == true).length == 0) {
        return this.$message.warning("没有更改任何数据，无需保存");
      }
      console.log(this.budgetList.filter(e => e.isChanged == true))
      const { result: res } = await this.$http.fetchData(
        "Budget/UpdateBudgetList",
        {
          budgetList: this.budgetList.filter(e => e.isChanged == true)
        }
      );
      if (res == "success") {
        this.$message.success("保存预算数据成功");
        this.getBudget(false);
      } else {
        this.$message.error("保存预算数据失败");
      }
    },
    async getLock() {
      //判断是否预算锁定
      const { result: res } = await this.$http.get("/Home/GetLock?type=budget");
      if (res == 1) {
        this.isLocked = true;
      } else {
        this.isLocked = false;
      }
    },
    async lockUnlock() {
      var op = this.isLocked ? "unlock" : "lock";

      const { result: res } = await this.$http.fetchData(
        "Budget/UpdateBudgetLock",
        {
          op: op
        }
      );
      if (res == "success") {
        var tip = op == "unlock" ? "解锁成功" : "锁定成功";
        this.isLocked = !this.isLocked;
        this.$message.success(tip);
      } else {
        this.$message.error("修改失败");
      }
    },
    async importBudget() {
      let file = this.$refs.upload.uploadFiles.pop().raw; //这里获取上传的文件对象
      let formData = new FormData();
      let url = "";
      let importType = "";
        formData.append("file", file);
        formData.append("year", this.searchList.year);
      if (this.uploadType == "price") {
        url = "ImportBudgetPrice";
        importType = "价格";
      } else if (this.uploadType == "sale") {
        url = "ImportBudgetSale";
        importType = "销量";
      } else if (this.uploadType == "cost") {
        url = "ImportBudgetCost";
        importType = "成本";
      }
        const { result: res } = await this.$http.post(`/Budget/${url}`, formData);
        this.importDialogVisible = false;
        if (res == "success") {
            this.importDialogVisible = false;
            return this.$message.success("上传成功");
        } else if (res == "successMail") {
            return this.$message.success("导入请求已被记录，后面会以邮件形式通知。");
        }
          else if (!!res.errorFilePath) {  //返回报错文件和报错信息
          this.$notify({
            title: "导入错误",
            message: res.promptMessage,
            type: "warning",
            position: 'bottom-left'
          }); 
        var todayDate = new Date();
        const errorFile = await this.$http.downLoad(
          `/Home/Download?fileName=${res.errorFilePath}`
        );
        var blob = new Blob([errorFile.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute(
          "download",
          `预算分配导入${importType}失败_${todayDate.getFullYear()}${todayDate.getMonth() +
            1}${todayDate.getDate()}.xlsx`
        );
        a.click();
        URL.revokeObjectURL(objectUrl);
        } 

        else {
            return this.$message.error("上传失败：" + res);
        }/*else if (res.errorType) {
        return this.$message.error("上传失败：" + res.errorType);
      } else {
        return this.$message.error("上传失败");
      }*/
      },
      async showInitDialog() {
          this.importTitile = "初始化预算";
          this.InitDialogVisible = true;
      },
      async initbudget() {
          const { result: res } = await this.$http.fetchData(
              "Budget/initBudget",
              {
                  inityear: this.searchList.year
              }
          );
          if (res == "success") {  
              this.InitDialogVisible = false;
              this.$message.success("初始化成功");              
          } else {
              this.$message.error("初始化失败，请联系管理员后台删除。");
          }
      },
    async showImportDialog(type) {
      this.uploadType = type;
      if (this.uploadType == "price") {
        this.importTitile = "导入价格";
      } else if (this.uploadType == "sale") {
        this.importTitile = "导入销量";
      } else if (this.uploadType == "cost") {
        this.importTitile = "导入成本";
      }
      this.importDialogVisible = true;
      },     
    importDialogClosed() {
      this.$refs.importFormRef.resetFields();
    },
    async exportbudget(flag) {
      const { result: fileName } = await this.$http.fetchData(
        "Budget/ExportBudgetExcel",
        {
          brand: this.searchList.brand,
          productName: this.searchList.productName,
          productLine: this.searchList.productLine,
          agicodeId: !this.searchList.agicodeId
            ? 0
            : +this.searchList.agicodeId,
          areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
          saleGroupId: !this.searchList.salegroupId
            ? 0
            : +this.searchList.salegroupId,
          month: this.searchList.month,
            year: this.searchList.year,
            isExport: !flag,
            isStored: flag
        }
        );
        if (fileName == "success") {
            return this.$message.success("导出请求已被记录，后面会以邮件形式通知下载。");
        } else {
            this.downloadFileName = fileName;
            var todayDate = new Date();
            const res = await this.$http.downLoad(
              `/Home/Download?fileName=${this.downloadFileName}`
            );
            var blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            var objectUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.setAttribute("style", "display:none");
            a.setAttribute("href", objectUrl);
            a.setAttribute(
              "download",
              `预算分配_${todayDate.getFullYear()}${todayDate.getMonth() +
                1}${todayDate.getDate()}.xlsx`
            );
            a.click();
            URL.revokeObjectURL(objectUrl);
        }
    },
    handleSelectChange() {
      this.$forceUpdate();
    },
    getCurrentDate() {
      let date = new Date();
      this.currentYear = date.getFullYear(); //获取完整的年份(4位)
      this.searchList.year = this.currentYear;
    },
    async areaChange(){
      if(!!this.searchList.areaId)
      {
       this.searchList.salegroupId = null
       const { result: sglist } = await this.$http.get(
        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchList.areaId}`
       );
      this.saleGroupDDL = sglist;
      }
      },
      async reCaculateAreaAvgNetPrice() {
          if (this.budgetList.findIndex(e => e.isChanged == true) > -1) {  //判断是否有调整过的，如果有重新获取后台计算
              const { result: res } = await this.$http.fetchData("/Budget/ReCalculateAreaAvgNetPrice",
                  {  budgetList: this.budgetList.filter(e => e.isChanged == true) }); // 重新计算所有的大区平均净价格值
              this.budgetAvgNetPriceList = res;
              this.budgetlist.forEach(e => {
                  e.budgetAreaAvgPrice = this.budgetAvgNetPriceList.find(i => i.areaId == e.areaId && i.budgetMonth == e.intmonth && i.productId == e.budgetProductId).avgNetPrice;
              });
              // pending still have error, API aready reply correct value but page cannot show.
          }
     }
  }
};
</script>

<style lang="less" scoped>
.lastBtnRow {
  position: relative;
  bottom: 15px;
}
</style>