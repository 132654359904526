<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>铺货折扣申请记录</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form ref="searchFormRef">
        <el-row>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>大区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchForm.areaId" filterable placeholder @change="areaChange">
                    <el-option
                      v-for="item in areaDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="areaId">
                  <el-select v-model="searchForm.productName" filterable placeholder>
                    <el-option
                      v-for="item in productNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="10">
            <el-row>
              <el-col :span="4">
                <el-form-item>
                  <label>申请时间</label>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-date-picker v-model="searchForm.applyStart" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="1" :offset="4">
                <el-form-item>
                  <span>至</span>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item>
                  <el-date-picker v-model="searchForm.applyEnd" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>小区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchForm.saleGroupId" filterable placeholder @change="handleSelectChange">
                    <el-option
                      v-for="item in sgDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品线</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="areaId">
                  <el-select v-model="searchForm.productLine" filterable placeholder>
                    <el-option
                      v-for="item in productLineDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="10">
            <el-row>
              <el-col :span="4">
                <el-form-item>
                  <label>审批时间</label>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-date-picker v-model="searchForm.approvalStart" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="1" :offset="4">
                <el-form-item>
                  <span>至</span>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item>
                  <el-date-picker v-model="searchForm.approvalEnd" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button type="primary" @click="resetSearch">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>
    <el-card class="result-table" shadow="never" v-show="tableShow">
        <el-table :data="recordList" border stripe height="500">
            <el-table-column prop="requestId" label="申请单号" width="100px">
                <template v-slot="scope">
                    <el-button class="requestIDBtn"
                               @click="goApplication(scope.row)">{{scope.row.requestId}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="requestor" label="申请人" width="140px"></el-table-column>
            <el-table-column prop="productName" label="产品"></el-table-column>
            <el-table-column prop="saleGroupName" label="小区"></el-table-column>
            <el-table-column label="申请时间" width="160px">
                <template v-slot="scope">
                    <span>{{scope.row.requestTime | dateFormat}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="approvalStatus" width="150px" label="当前步骤审批状态"></el-table-column>
            <el-table-column label="当前步骤审批时间">
                <template v-slot="scope">
                    <span>{{scope.row.approvalTime | dateFormat}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="requestCompletedStatus" width="100px" label="请求状态"></el-table-column>
            <el-table-column prop label="操作" width="300px">
                <template v-slot="scope">
                    <el-button class="operation"
                               @click="showApprove(scope.row)"
                               v-show="scope.row.isWaitingApproval">批准</el-button>
                    <el-button class="operation"
                               @click="showReject(scope.row)"
                               v-show="scope.row.isWaitingApproval">拒绝</el-button>
                    <el-button class="operation" @click="showRecord(scope.row)">审批记录</el-button>
                </template>
            </el-table-column>
        </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :hide-on-single-page="true"
        :total="listTotalNum"
      ></el-pagination>
    </el-card>

    <!-- 批准的弹出框 -->
    <el-dialog
      title="批准"
      :visible.sync="approveDialogVisible"
      width="40%"
      @close="approveDialogClosed"
      :close-on-click-modal='false'
    >
      <el-form ref="approveFormRef" label-width="100px">
        <el-form-item label="理由">
          <el-input v-model="approveReason" placeholder></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="approveRequest()">确定通过</el-button>
      </span>
    </el-dialog>

    <!-- 拒绝的弹出框 -->
    <el-dialog
      title="拒绝"
      :visible.sync="rejectDialogVisible"
      width="40%"
      @close="rejectDialogClosed"
      :close-on-click-modal='false'
    >
      <el-form ref="rejectFormRef" label-width="100px">
        <el-form-item label="理由">
          <el-input v-model="rejectReason" placeholder></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rejectRequest">确定拒绝</el-button>
      </span>
    </el-dialog>

    <!-- 审批记录的弹出框 -->
    <el-dialog
      :title="recordDialogTitle"
      :visible.sync="recordDialogVisible"
      width="80%"
      @close="recordDialogClosed"
      :close-on-click-modal='false'
    >
      <el-table :data="recordTable" border stripe>
        <el-table-column prop="roleName" label="审批角色"></el-table-column>
        <el-table-column prop="approver" label="审批人"></el-table-column>
        <el-table-column prop="approvalStatus" label="审批状态"></el-table-column>
        <el-table-column label="审批时间">
          <template v-slot="scope">{{scope.row.approvalTime|dateFormat}}</template>
        </el-table-column>
        <el-table-column prop="comment" label="理由"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        areaId: null,
        saleGroupId: null,
        productName: null,
        applyStart: null,
        applyEnd: null,
        productLine: null,
        approvalStart: null,
        approvalEnd: null
      },
      productNameDDL: [],
      productLineDDL: [],
      sgDDL: [],
      areaDDL: [],
      queryInfo: {
        pagenum: 1,
        pagesize: 10
      },
      tableShow: false,
      recordList: [],
      approveDialogVisible: false,
      rejectDialogVisible: false,
      approveReason: null,
      rejectReason: null,
      requestId: 0, //当前正在操作的申请ID
      roleId: 0, //当前审批人的角色id
      recordDialogTitle: "",
      recordTable: [],
      recordDialogVisible: false,
      listTotalNum: 0
    };
  },
  created() {
      this.getDDL();
      this.search();
  },
  methods: {
    async getDDL() {
      const { result: area } = await this.$http.get(
        "/Home/GetSelectList?lookup=userArea"
      );
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: productName } = await this.$http.get(
        "/Home/GetSelectList?lookup=productName"
      );
      const { result: productLine } = await this.$http.get(
        "/Home/GetSelectList?lookup=productLine"
      );
      this.productNameDDL = productName;
      this.productLineDDL = productLine;
      this.sgDDL = saleGroup;
      this.areaDDL = area;
    },
    async search() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/GetDiscountRequests",
        {
          areaId: this.searchForm.areaId,
          saleGroupId: this.searchForm.saleGroupId,
          productName: this.searchForm.productName,
          productLine: this.searchForm.productLine,
          applyStart: this.searchForm.applyStart,
          applyEnd: this.searchForm.applyEnd,
          approvalStart: this.searchForm.approvalStart,
          approvalEnd: this.searchForm.approvalEnd,
          pageNumber: this.queryInfo.pagenum - 1,
          pageSize: this.queryInfo.pagesize
        }
      );
      this.recordList = res.requests;
      this.listTotalNum = res.total;
      this.tableShow = true;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.search();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.search();
    },

    async resetSearch() {
      this.searchForm = {
        areaId: null,
        productName: null,
        applyStart: null,
        applyEnd: null,
        productLine: null,
        approvalStart: null,
        approvalEnd: null
      };
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      this.sgDDL = saleGroup;
    },
    showApprove(row) {
      this.requestId = row.requestId;
      this.roleId = row.roleId;
      this.approveDialogVisible = true;
    },
    approve() {
      this.approveDialogVisible = false;
    },
    approveDialogClosed() {
      this.approveReason = null;
    },
    showReject(row) {
      this.requestId = row.requestId;
      this.roleId = row.roleId;
      this.rejectDialogVisible = true;
    },
    reject() {
      this.rejectDialogVisible = false;
    },
    rejectDialogClosed() {
      this.rejectReason = null;
    },
    recordDialogClosed() {},
    async showRecord(row) {
      this.recordDialogTitle = `申请单${row.requestId}的审批记录`;
      const { result: record } = await this.$http.get(
        `/Forecast/GetRequestApprovalHistories?requestId=${row.requestId}`
      );
      this.recordTable = record;

      this.recordDialogVisible = true;
    },
    goApplication(row) {
      window.sessionStorage.setItem("activePath", "/" + "adjustapplication");
        window.sessionStorage.setItem("activeMenuId", 15);
      //this.$router.push({
      //  name: "adjustapplication",
      //  params: {
      //    requestId: row.requestId,
      //    fromApprover: row.isWaitingApproval,
      //    opRoleId:row.roleId,
      //    productName:row.productName,
      //    saleGroupId:row.saleGroupId,
      //    year:row.year
      //  }
      //});
        let pathInfo = this.$router.resolve({
            path: '/adjustapplication',
            query: {
                //data: {
                    requestId: row.requestId,
                fromApprover: row.isWaitingApproval,
                    opRoleId: row.roleId,
                    productName: row.productName,
                    saleGroupId: row.saleGroupId,
                    year: row.year
                //}
            }
        });
        window.open(pathInfo.href, '_blank');
    },
    async rejectRequest() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/ApproveRejectRequest",
        {
          roleId:this.roleId,
          requestId: this.requestId,
          comment: this.rejectReason,
          op: "reject"
        }
      );
      if (res == "success") {
        this.$message.success("拒绝成功");
        this.rejectDialogVisible = false;
        this.search();
      } else {
        this.$message.error("拒绝失败");
      }
    },
    async approveRequest() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/ApproveRejectRequest",
        {
          roleId:this.roleId,
          requestId: this.requestId,
          comment: this.approveReason,
          op: "approve"
        }
      );
      if (res == "success") {
        this.$message.success("批准成功");
        this.approveDialogVisible = false;
        this.search();
      } else {
        this.$message.error("批准失败");
      }
    },
    handleSelectChange() {
      this.$forceUpdate();
    },
    async areaChange(){
     if(!!this.searchForm.areaId)
      {
       this.searchForm.saleGroupId = null
       const { result: sglist } = await this.$http.get(
        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchForm.areaId}`
       );
      this.sgDDL = sglist;
      }
    }

  }
};
</script>

<style lang="less" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 165px;
}
.operation {
  height: 40px !important;
}
.el-button:focus,
.el-button:hover {
  background-color: #fff;
  border-color: #dcdfe6;
  color: #aab400;
}
.requestIDBtn {
  background-color: transparent;
  border-color: transparent;
  color: #aab400;
  text-decoration: underline;
}
.requestIDBtn:hover {
  background-color: transparent;
  border-color: transparent;
}
</style>