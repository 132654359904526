<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <!--<el-breadcrumb-item>用户列表</el-breadcrumb-item>-->
        </el-breadcrumb>
        <el-card class="search-item" shadow="never">
            <span>搜索条件</span>
        </el-card>
        <el-card class="search-form" shadow="never">
            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchList">
                <el-form-item label="用户名：">
                    <el-input v-model="searchList.userName" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="用户邮箱：">
                    <el-input v-model="searchList.userEmail" placeholder="请输入用户邮箱"></el-input>
                </el-form-item>
                <el-form-item style="right:auto">
                    <el-button type="primary" @click="getUserList">查询</el-button>
                </el-form-item>
                <el-form-item style="right:auto">
                    <el-button type="primary" @click="showAddDialog">新增用户</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="search-item" shadow="never">
            <span>查询结果</span>
        </el-card>
        <el-card class="result-table" shadow="never">

            <!-- 列表 -->
            <el-table :data="userlist" border stripe  height="500">
                <el-table-column width="80px" prop="userId" label="用户ID"></el-table-column>
                <el-table-column prop="userName" label="用户姓名"></el-table-column>
                <el-table-column prop="userEmail" label="用户邮箱"></el-table-column>
                <el-table-column prop="userRole" label="用户角色"></el-table-column>
                <el-table-column prop="userArea" label="大区数据权限"></el-table-column>
                <el-table-column width="80px" prop="userIsActive" label="是否有效">
                    <template slot-scope="scope">
                        <span  v-if="scope.row.userIsActive === true">是</span>
                        <span  v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column width="80px" label="操作">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="修改用户" :enterable="false" placement="top">
                            <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="showEditDialog(scope.row.userId)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 修改用户的对话框 -->
            <el-dialog title="修改用户信息"
                       :visible.sync="editDialogVisible"
                       width="40%"
                       @close="editDialogClosed"     
                       :close-on-click-modal='false'
                       >
                <el-form :model="editUserForm"
                         ref="editUserFormRef"
                         label-width="100px"
                         :rules="editUserRules">
                    <el-form-item label="用户姓名" prop="userName">
                        <el-input v-model="editUserForm.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮箱" prop="userEmail">
                        <el-input v-model="editUserForm.userEmail"></el-input>
                    </el-form-item>
                    <el-form-item label="用户角色" prop="userRoleIDList">
                        <el-select v-model="editUserForm.userRoleIDList"   multiple placeholder="请选择">
                            <el-option v-for="item in roleList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="大区数据权限">
                        <el-select v-model="editUserForm.userAreaIDList" multiple placeholder="请选择">
                            <el-option v-for="item in userAreaList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="editUserForm.userIsActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveUser">保存</el-button>
                </span>
            </el-dialog>

             <!-- 新增用户的对话框 -->
            <el-dialog title="添加用户信息"
                       :visible.sync="addDialogVisible"
                       width="40%"
                       @close="addDialogClosed"
                       :close-on-click-modal='false'
                       >
                <el-form :model="addUserForm"
                         ref="addUserFormRef"
                         label-width="100px"
                         :rules="addUserRules">
                    <el-form-item label="用户姓名" prop="userName">
                        <el-input v-model="addUserForm.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮箱" prop="userEmail">
                        <el-input v-model="addUserForm.userEmail"></el-input>
                    </el-form-item>
                    <el-form-item label="用户角色" prop="userRoleIDList">
                        <el-select v-model="addUserForm.userRoleIDList"  multiple placeholder="请选择">
                            <el-option v-for="item in roleList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="大区数据权限">
                        <el-select v-model="addUserForm.userAreaIDList" multiple placeholder="请选择">
                            <el-option v-for="item in userAreaList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="addUserForm.userIsActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addUser">保存</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>


<script>
  export default {
    data() {
      var checkEmail = (rule, value, cb) => {
      const regEmail = /^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法邮箱！"));
    };
      var checkRoles = (rule,value,cb)=>{
          if(value.length<2){
        return cb();
          }
          cb(new Error("请只选择一个角色"))
      }



      return {
        searchList: {
          userName: '',
          userEmail: ''
        },
        userlist:[],
        editDialogVisible: false,
        editUserForm: {userId:0,userName:'',userEmail:'',userRoleIDList:[],userAreaIDList:[],userIsActive:true},
        roleList:[],
        userAreaList:[],
        addDialogVisible:false,
        addUserForm:{userId:0,userName:'',userEmail:'',userRoleIDList:[],userAreaIDList:[],userIsActive:true},
        editUserRules:{
            userName:[{required: true, message: "请输入用户名", trigger: "blur"}],
            userEmail:[{required: true, message: "请输入合法邮箱！", trigger: "blur"},{validator: checkEmail, trigger: "change"}],
            //userRoleIDList:[{required: true, message: "请只选择一个角色", trigger: "blur"},{validator: checkRoles, trigger: "change"}]
        },
        addUserRules:{
            userName:[{required: true, message: "请输入用户名", trigger: "blur"}],
            userEmail:[{required: true, message: "请输入合法邮箱！", trigger: "blur"},{validator: checkEmail, trigger: "change"}],
            //userRoleIDList:[{required: true, message: "请只选择一个角色", trigger: "blur"},{validator: checkRoles, trigger: "change"}]
        },
      }
    },
    created(){
        this.getUserList()
    },
    methods: {
      async getUserList() {
        const {result:res} = await this.$http.fetchData('user/GetUserList',{userName:this.searchList.userName,userEmail:this.searchList.userEmail,userId:0})
        this.userlist= res
        if(this.userlist.length==0)
        {
           return this.$message.warning("没有对应条件的用户数据");
        }
      },
      async showEditDialog (userId) {
        const {result:res} = await this.$http.fetchData('user/GetUserList',{userName:null,userEmail:null,userId:userId})
        const {result:roleList} = await this.$http.get('/Home/GetSelectList?lookup=userRole')
        const {result:userAreaList} = await this.$http.get('/Home/GetSelectList?lookup=userArea')
        this.roleList = roleList
        this.userAreaList = userAreaList
        this.editUserForm = res[0]
        this.editDialogVisible = true
      },
       editDialogClosed () {
        this.$refs.editUserFormRef.resetFields()
      },
      addDialogClosed()
      {
        this.$refs.addUserFormRef.resetFields()
      },
      async saveUser()
      {
         this.$refs["editUserFormRef"].validate(async (valid) => {
         if (valid) {

          if(this.editUserForm.userAreaIDList.length == 0){
              
              this.userAreaList.forEach(e=>this.editUserForm.userAreaIDList.push(e.value))
              this.$message.warning("大区权限没有选择，将设置为全部大区")
          }

         const {result:res} = await this.$http.fetchData('user/UpdateUser',
         {userId:this.editUserForm.userId,userName:this.editUserForm.userName,userEmail:this.editUserForm.userEmail,
          userRoleIDList:this.editUserForm.userRoleIDList.join(";"),userAreaIDList:this.editUserForm.userAreaIDList.join(";"),userIsActive:this.editUserForm.userIsActive
         })
         if(res==="success")
         {
           this.getUserList()
           this.editDialogVisible = false
           return this.$message.success("修改用户信息成功");
         }
         else{
           return this.$message.error("修改用户信息失败");
         }
         }})
      },
      async addUser()
      {
         this.$refs["addUserFormRef"].validate(async (valid) => {
         if (valid) {
              if(this.addUserForm.userAreaIDList.length == 0){
              
              this.userAreaList.forEach(e=>this.addUserForm.userAreaIDList.push(e.value))
              this.$message.warning("大区权限没有选择，将设置为全部大区")
          }

         const {result:res} = await this.$http.fetchData('user/UpdateUser',
         {userId:this.addUserForm.userId,userName:this.addUserForm.userName,userEmail:this.addUserForm.userEmail,
          userRoleIDList:this.addUserForm.userRoleIDList.join(";"),userAreaIDList:this.addUserForm.userAreaIDList.join(";"),userIsActive:this.addUserForm.userIsActive
         })
         if(res==="success")
         {
           this.getUserList()
           this.addDialogVisible = false
           return this.$message.success("新增用户信息成功");
         }
         else{
           return this.$message.error("新增用户信息失败");
         } }})
      },
      async showAddDialog () {
        const {result:roleList} = await this.$http.get('/Home/GetSelectList?lookup=userRole')
        const {result:userAreaList} = await this.$http.get('/Home/GetSelectList?lookup=userArea')
        this.roleList = roleList
        this.userAreaList = userAreaList
        this.addDialogVisible = true
      },
      
    }
  }
</script>






<style lang="less" scoped>
.el-switch{
    margin-top:9px;
}
</style>