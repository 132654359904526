<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>铺货折扣调整申请</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never" >
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never" >
      <!-- 搜索表单 -->
      <el-form ref="searchFormRef">
        <el-row>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item prop="productName">
                  <label>
                    <span class="redMark">*</span>产品
                  </label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchForm.productName" filterable placeholder>
                    <el-option
                      v-for="item in productNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>
                    <span class="redMark">*</span>小区
                  </label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="saleGroupId">
                  <el-select v-model="searchForm.saleGroupId" filterable placeholder>
                    <el-option
                      v-for="item in SGNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>
                    <span class="redMark">*</span>年份
                  </label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="year">
                  <el-select v-model="searchForm.year" filterable placeholder>
                    <el-option
                      v-for="item in YearDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5" :offset="3" v-show="!ifFromApprover">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="7" :offset="3">
                <el-form-item>
                  <el-button type="primary" v-show="haveEditAccess" @click="edit">编辑</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search-item" shadow="never" >
      <span>查询结果</span>
    </el-card>

    <el-card class="result-table" shadow="never" v-show="tableShow" >
        <el-table :data="saleGroupList"
                  :summary-method="getSGSummaries"
                  show-summary
                  border
                  stripe
                  :span-method="sgtableSpan"
                  :row-style="{height:'10px'}"
                  :cell-style="{padding:'2px'}">
            <el-table-column fixed>
                <template>
                    <span>小区数据</span>
                </template>
            </el-table-column>
            <el-table-column fixed prop="monthLabel" label="月份" width="55px"></el-table-column>
            <el-table-column prop="basicDiscount" :formatter='keepDigitals' label="基本折扣" width="55px"></el-table-column>
            <el-table-column prop="lastYearDiscount" :formatter='keepDigitals' label="上年度铺货折扣" width="60px"></el-table-column>
            <el-table-column prop="lastYearSales" :formatter='keepDigitals' label="上年度销量" width="60px">
                <template v-slot="scope">
                    {{scope.row.lastYearSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column prop="forecastBillingPrice" :formatter='keepDigitals' label="发票价" width="70px">
                <template v-slot="scope">
                    {{scope.row.forecastBillingPrice|thousandth}}
                </template>
            </el-table-column>
            <el-table-column prop="conditionDiscount" :formatter='keepDigitals' label="有条件折扣" width="100px">
                <template v-slot="scope">
                    {{scope.row.conditionDiscount|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="是否为额外折扣" width="50px">
                <template v-slot="scope">
                    <span>{{scope.row.isconditionDiscount > 0?'是':'否'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="foc" :formatter='keepDigitals' label="预测铺货折扣FOC金额" width="100px">
                <template v-slot="scope">
                    {{scope.row.foc|thousandth}}
                </template>
            </el-table-column>
            <el-table-column prop="focPercent" :formatter='keepDigitals' label="预测铺货折扣FOC%" width="100px">
                <template v-slot="scope">
                    {{scope.row.focPercent|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预测铺货折扣" width="100px">
                <template v-slot="scope">
                    <!--  赋值都赋request值，在初始化时进行过处理 -->
                    <span v-if="!haveEditAccess">{{scope.row.requestDiscount|thousandth}}</span>
                    <!--  <span v-show="scope.row.forecastId!=0&&!haveEditAccess"></span> -->
                    <!--             <el-input
              v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"
              v-if="haveEditAccess&&!ifFromApprover"
              @change="inputChange(scope.row)"
              v-model="scope.row.requestDiscount"
              size="small"
            ></el-input> -->
                    <div class="el-input--small" v-if="haveEditAccess&&!ifFromApprover">
                        <currency-input v-model="scope.row.requestDiscount" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :formatter='keepDigitals' prop="requestMDR" label="预测铺货折扣MDR" width="100px">
                <template v-slot="scope">
                    <!--  赋值都赋request值，在初始化时进行过处理 -->
                    <span v-if="!haveEditAccess">{{scope.row.requestMDR|thousandth}}</span>
                    <!--             <el-input
              v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"
              v-if="haveEditAccess&&!ifFromApprover"
              @change="inputChange(scope.row)"
              v-model="scope.row.requestMDR"
              size="small"
            ></el-input> -->
                    <div class="el-input--small" v-if="haveEditAccess&&!ifFromApprover">
                        <currency-input v-model="scope.row.requestMDR" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预测铺货折扣MDR%" width="100px">
                <template slot-scope="scope">
                    {{scope.row.forecastMDRPercent}}
                    <span v-show="scope.row.forecastId!=0">%</span>
                </template>
            </el-table-column>
            <el-table-column :formatter='keepDigitals' prop="forecastMDRAmount" label="预测铺货折扣MDR总金额" width="100px">
                <template v-slot="scope">
                    {{scope.row.forecastMDRAmount|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预估销量" prop="requestSales" width="150px">
                <!--  赋值都赋request值，在初始化时进行过处理 -->
                <template v-slot="scope">
                    <span v-if="!haveEditAccess">{{scope.row.requestSales|thousandth}}</span>
                    <!--             <el-input
              v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"
              v-if="haveEditAccess&&!ifFromApprover"
              @change="inputChange(scope.row)"
              v-model="scope.row.requestSales"
              size="small"
            ></el-input> -->
                    <div class="el-input--small" v-if="haveEditAccess&&!ifFromApprover">
                        <currency-input v-model="scope.row.requestSales" class="el-input__inner currencyInput" @change="inputChange(scope.row)" :currency="null" v-bind:disabled="inputDisable||scope.row.month<currentMonth||scope.row.forecastId==0"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预估净销售" prop="forecastNetSales" width="100px">
                <template v-slot="scope">
                    {{scope.row.forecastNetSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预估利润" prop="forecastProfit" :formatter='keepDigitals' width="100px">
                <template v-slot="scope">
                    {{scope.row.forecastProfit|thousandth}}
                </template>
            </el-table-column>
            <el-table-column :formatter='keepDigitals' prop="budgetDiscountPercent" label="预算铺货折扣" width="100px">
                <template v-slot="scope">
                    {{scope.row.budgetDiscountPercent|thousandth}}
                </template>
            </el-table-column>
            <el-table-column prop="budgetSales" label="预算销量">
                <template v-slot="scope">
                    {{scope.row.budgetSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column width="100px" prop="budgetNetSales" :formatter='keepDigitals' label="预算净销售">
                <template v-slot="scope">
                    {{scope.row.budgetNetSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column width="100px" prop="budgetProfit" :formatter='keepDigitals' label="预算利润">
                <template v-slot="scope">
                    {{scope.row.budgetProfit|thousandth}}
                </template>
            </el-table-column>
            <el-table-column :formatter='keepDigitals' prop="netSalesVS" label="净销售vs预算">
                <template v-slot="scope">
                    {{scope.row.netSalesVS|thousandth}}
                </template>
            </el-table-column>
            <el-table-column :formatter='keepDigitals' prop="profitVS" label="利润vs预算">
                <template v-slot="scope">
                    {{scope.row.profitVS|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="是否调整">
                <template v-slot="scope">
                    <span>{{scope.row.isAdjust?'是':'否'}}</span>
                </template>
            </el-table-column>
        </el-table>

      <el-card class="result-table areaTable" shadow="never">
          <el-table :data="areaList" border stripe
                    :row-style="{height:'10px'}"
                    :cell-style="{padding:'2px'}">
              <el-table-column>
                  <template>
                      <span>大区数据</span>
                  </template>
              </el-table-column>

              <el-table-column width="300px">
                  <template>
                      <span>全年汇总</span>
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' width="150px" label="有条件折扣">
                  <template v-slot="scope">
                      {{scope.row.conditionDiscount|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' width="200px" label="预测铺货折扣FOC金额">
                  <template v-slot="scope">
                      {{scope.row.foc|thousandth}}
                  </template>
              </el-table-column>
              <!--<el-table-column :formatter='keepDigitals' width="100px" label="预测铺货折扣FOC%">
                  <template v-slot="scope">
                      {{scope.row.focPercent|thousandth}}
                  </template>
              </el-table-column>-->
              <el-table-column :formatter='keepDigitals' width="100px" label="预测铺货折扣">
                  <template v-slot="scope">
                      {{scope.row.forecastDiscountPercent|thousandth}}
                  </template>
              </el-table-column>
              <!--  <el-table-column prop="forecastMDRPercent" label="预测铺货折扣MDR%"></el-table-column> -->
              <el-table-column width="300px" label="预测铺货折扣MDR总金额">
                  <template v-slot="scope">
                      {{scope.row.forecastMDRAmount|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="150px" label="预估销量">
                  <template v-slot="scope">
                      {{scope.row.forecastSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="100px" label="预估净销售">
                  <template v-slot="scope">
                      {{scope.row.forecastNetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="100px" label="预估利润">
                  <template v-slot="scope">
                      {{scope.row.forecastProfit|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="100px" :formatter='keepDigitals' label="预算铺货折扣">
                  <template v-slot="scope">
                      {{scope.row.budgetDiscountPercent|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column label="预算销量">
                  <template v-slot="scope">
                      {{scope.row.budgetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="100px" label="预算净销售">
                  <template v-slot="scope">
                      {{scope.row.budgetNetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column width="100px" label="预算利润">
                  <template v-slot="scope">
                      {{scope.row.budgetProfit|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' label="净销售vs预算">
                  <template v-slot="scope">
                      {{scope.row.netSalesVS|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' label="利润vs预算">
                  <template v-slot="scope">
                      {{scope.row.profitVS|thousandth}}
                  </template>
              </el-table-column>
          </el-table>
      </el-card>

      <el-card class="result-table countryTable" shadow="never">
          <el-table :data="countryList" border stripe
                    :row-style="{height:'10px'}"
                    :cell-style="{padding:'2px'}">
              <el-table-column>
                  <template>
                      <span>全国数据</span>
                  </template>
              </el-table-column>

              <el-table-column width="300px">
                  <template>
                      <span>全年汇总</span>
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' width="150px" label="有条件折扣">
                  <template v-slot="scope">
                      {{scope.row.conditionDiscount|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column :formatter='keepDigitals' width="200px" label="预测铺货折扣FOC金额">
                  <template v-slot="scope">
                      {{scope.row.foc|thousandth}}
                  </template>
              </el-table-column>
              <!--<el-table-column :formatter='keepDigitals' width="100px" label="预测铺货折扣FOC%">
                  <template v-slot="scope">
                      {{scope.row.focPercent|thousandth}}
                  </template>
              </el-table-column>-->
              <el-table-column prop="forecastDiscountPercent" :formatter='keepDigitals' width="100px" label="预测铺货折扣">
                  <template v-slot="scope">
                      {{scope.row.forecastDiscountPercent|thousandth}}
                  </template>
              </el-table-column>
              <!-- <el-table-column prop="forecastMDRPercent" label="预测铺货折扣MDR%"></el-table-column> -->
              <el-table-column prop="forecastMDRAmount" width="300px" label="预测铺货折扣MDR总金额">
                  <template v-slot="scope">
                      {{scope.row.forecastMDRAmount|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="forecastSales" width="150px" label="预估销量">
                  <template v-slot="scope">
                      {{scope.row.forecastSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="forecastNetSales" width="100px" label="预估净销售">
                  <template v-slot="scope">
                      {{scope.row.forecastNetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="forecastProfit" width="100px" label="预估利润">
                  <template v-slot="scope">
                      {{scope.row.forecastProfit|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="budgetDiscountPercent" width="100px" :formatter='keepDigitals' label="预算铺货折扣">
                  <template v-slot="scope">
                      {{scope.row.budgetDiscountPercent|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="budgetSales" label="预算销量">
                  <template v-slot="scope">
                      {{scope.row.budgetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="budgetNetSales" width="100px" label="预算净销售">
                  <template v-slot="scope">
                      {{scope.row.budgetNetSales|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="budgetProfit" width="100px" label="预算利润">
                  <template v-slot="scope">
                      {{scope.row.budgetProfit|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="netSalesVS" :formatter='keepDigitals' label="净销售vs预算">
                  <template v-slot="scope">
                      {{scope.row.netSalesVS|thousandth}}
                  </template>
              </el-table-column>
              <el-table-column prop="profitVS" :formatter='keepDigitals' label="利润vs预算">
                  <template v-slot="scope">
                      {{scope.row.profitVS|thousandth}}
                  </template>
              </el-table-column>
          </el-table>
      </el-card>

      <el-card class="search-form result-table reason" shadow="never">
        <el-row>
          <el-col :span="2">
            <span>调整原因</span>
          </el-col>
          <el-col :span="20">
            <el-input type="textarea" autosize placeholder="请输入理由" v-model="reason"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <br />
          <br />
        </el-row>
        <el-row>
          <el-col :span="20"></el-col>
        </el-row>
      </el-card>

      <el-card class="search-form attach" shadow="never">
        <el-row>
          <el-col :span="10">
            <el-table :data="attachList" border stripe>
              <el-table-column label="附件名称">
                <template v-slot="scope">
                  <a
                    @click="downLoadAttachedFile(scope.row.fileId,scope.row.fileName)"
                    class="attachName"
                  >{{scope.row.fileName}}</a>
                </template>
              </el-table-column>
              <el-table-column label="上传时间">
                <template v-slot="scope">
                  <a>{{scope.row.uploadTime|dateFormat}}</a>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <br />
        <el-row v-show="ifFromApprover">
          <el-col :span="2">
            <span>审批理由</span>
          </el-col>
          <el-col :span="20">
            <el-input type="textarea" autosize placeholder="请输入理由" v-model="approveRejectReason"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <br />
          <el-col :span="3" :offset="8">
              <el-button type="primary" @click="importDialogVisible=true">上传附件</el-button>
             </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              v-show="!ifFromApprover&&haveEditAccess&&isBudgetLocked"
              @click="saveDraft"
            >保存草稿</el-button>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              v-show="!ifFromApprover&&haveEditAccess&&isBudgetLocked"
              @click="saveCommit"
            >提交审批</el-button>
          </el-col>
            
          <el-col :span="3">
            <el-button type="primary" v-show="ifFromApprover&&isWaitingApprove" @click="approveRequest()">批准</el-button>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" v-show="ifFromApprover&&isWaitingApprove" @click="rejectRequest()">拒绝</el-button>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              v-show="IsReject&&!isClosed"
              @click="closeRequest"
            >关闭申请</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-card>

    <el-dialog
      title="附件上传"
      :visible.sync="importDialogVisible"
      width="40%"
      @close="importDialogClosed"
      :close-on-click-modal='false'
    >
      <el-form ref="importFormRef" label-width="100px">
        <el-form-item label="上传文件：">
          <el-upload
            class="upload"
            ref="upload"
            action
            :file-list="fileList"
            :multiple="false"
            :show-file-list="true"
            :limit="1"
            :auto-upload="false"
            accept=".xlsx"
          >
            <el-button slot="trigger" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传一个文件，需要先删除已选择的文件，然后重新选择</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确定上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        productName: "",
        saleGroupId: "",
        year: ""
      },
      productNameDDL: [],
      SGNameDDL: [],
      YearDDL: [],
      saleGroupList: [],
      areaList: [],
      countryList: [],
      normalMonthList: [],
      sgTemplateObj: {
        productId: null,
        productName: null,
        productBrand: null,
        productSize: null,
        productLine: null,
        areaId: null,
        areaName: null,
        saleGroupId: null,
        saleGroupName: null,
        year: null,
        month: null,
        basicDiscount: null,
        lastYearDiscount: null,
        lastYearSales: null,
        forecastBillingPrice: null,
        forecastDiscountPercent: null,
        forecastMDR: null,
        forecastMDRPercent: null,
        forecastMDRAmount: null,
        forecastSales: null,
        forecastNetSales: null,
        forecastProfit: null,
        budgetDiscountPercent: null,
        budgetSales: null,
        budgetNetSales: null,
        budgetProfit: null,
        requestId: null,
        requestReason: null,
        requestTime: null,
        requestorName: null,
        requestorEmail: null,
        detailId: null,
        requestDiscount: null,
        requestMDR: null,
        requestSales: null,
        isAdjust: false,
        netSalesVS: null,
        profitVSBudget: null,
        profitVS: null,
        forecastId: 0

        },
        isBudgetLocked: false,
      reason: null,
      forecastDiscountSum: 0,
      budgetDiscountSum: 0,
      forecastSaleSum: 0,
      budgetSaleSum: 0,
      tableShow: false,
      attachList: [],
      isLoginUserAsAreaMarket: false, //是否当前用户是大区市场
      haveEditAccess: false,
      inputDisable: true,
      currentYear: null,
      currentMonth: null,
      importDialogVisible: false,
      fileList: [],
      ifFromApprover: false,
      approveRejectReason: null,
      userId: null,
      requestId: 0,
      fileIdListStr: null,
      attachFileIdList: [],
      requestStatusId: null,
      workFlowStepId: null,
      mdrSum: 0,
      mdrPercentSum:0,
      mdrPercentSumSaleBill:0,
      tableCaculate: null,
      oldforecastDiscountSum: 0,
      oldforecastSaleSum: 0,
      newSGDiscountSum: 0, //小区全年总铺货折扣
      newSGSalesSum: 0,//小区全年铺货销量
      isWaitingApprove: false,
        isClosed: false,
        IsReject : false
    };
  },
  created() {
    this.getDDL();
    this.checkifFromRecordPage();
    this.getCurrentDate();
    this.getLoginUserInfo();
    this.checkBudgetLock();
  },
  methods: {
    async getDDL() {
      const { result: productName } = await this.$http.get(
        "/Home/GetSelectList?lookup=productName"
      );
      const { result: sgList } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: yearList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      const { result: monthList } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      this.productNameDDL = productName;
      this.SGNameDDL = sgList;
      this.YearDDL = yearList;
      this.normalMonthList = monthList;
      },
      async checkBudgetLock() {
          //判断是否预算锁定
          const { result: res } = await this.$http.get("/Home/GetLock?type=budget");
          if (res == 1) {
              this.isBudgetLocked = true;
          } else {
              this.isBudgetLocked = false;
          }
      },
    async search() {
      if (
        !this.searchForm.productName ||
        !this.searchForm.saleGroupId ||
        !this.searchForm.year
      ) {
        this.$message.warning("请选择所有必填项再搜索");
      }
      if (this.searchForm.year < this.currentYear) {
          this.haveEditAccess = false;
          
      } else {
        this.getLoginUserInfo();
        }
        if (this.searchForm.year > this.currentYear) {
            this.currentMonth = 1;
        }
      this.getSaleGroup();
    },
    async getSaleGroup() {
      this.saleGroupList = [];
      this.attachList = [];
      const { result: res } = await this.$http.fetchData(
        "/Forecast/GetDiscounts",
        {
          productName: this.searchForm.productName,
          saleGroupId: this.searchForm.saleGroupId,
          year: this.searchForm.year
        }
      );

      if (res.saleGroupData.length == 0) {
        this.tableShow = false;
        return this.$message.warning("没有对应搜索数据");
      }

      const { result: attachList } = await this.$http.get(
        `/Forecast/GetRequestAttachments?requestId=${res.saleGroupData[0].requestId}`
      );
      this.attachList = attachList;
      this.attachFileIdList = [];
      attachList.forEach(e => this.attachFileIdList.push(e.fileId));

      this.requestId = res.saleGroupData[0].requestId;

      this.workFlowStepId = res.saleGroupData[0].workFlowStepId; //step >0 表示待审批 不可以再提交或保存草稿
      for (let i = 1; i <= 12; i++) {
        if (res.saleGroupData.findIndex(e => e.month == i) > -1) {
          let index = res.saleGroupData.findIndex(e => e.month == i);
          res.saleGroupData[index].monthLabel = this.normalMonthList.find(
            x => x.value == i
          ).label;
        } else {
          let obj = Object.assign({}, this.sgTemplateObj);
          obj.month = +this.normalMonthList.find(x => x.value == i).value;
          obj.monthLabel = this.normalMonthList.find(y => y.value == i).label;
          res.saleGroupData.push(obj);
        }
      }

      res.saleGroupData.forEach(x => {
        //x.oldforecastNetSales = x.forecastNetSales;
        //x.oldforecastProfit = x.forecastProfit;
        x.requestDiscount = this.convertDecimal(x.requestDiscount)
        x.requestMDR = this.convertDecimal(x.requestMDR)
        if (x.isAdjust == true) {
          //保存老值用于计算
          let forecastNetPrice =this.convertDecimal(
            x.forecastBillingPrice *
            (1 -
              x.requestDiscount / x.forecastBillingPrice  -
              x.forecastOtherDiscount / 100)); // 预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣))
          x.forecastNetSales =
              this.convertDecimal(forecastNetPrice * x.requestSales); //预测-净销售 = 预测-净价格 X 预测-销量
            x.netSalesVS = x.forecastNetSales - x.budgetNetSales;           
          x.forecastProfit =
                this.convertDecimal((forecastNetPrice - x.forecastCost) * x.requestSales);
            x.profitVS = this.convertDecimal(x.forecastProfit - x.budgetProfit);
             //【预测利润】=（【预测净价格】-【成本】）*【预测销量】
          x.forecastMDRPercent = this.convertDecimal(x.requestMDR / x.forecastBillingPrice * 100);
          x.forecastMDRAmount = this.convertDecimal(x.requestSales * x.requestMDR);       
       }

      });
      res.saleGroupData.sort((a, b) => a.month - b.month);
      
      
      this.saleGroupList = res.saleGroupData;
      this.reason = res.reason;
      this.caculateDiscountSum();

      if(this.saleGroupList.findIndex(e=>e.isAdjust==true)>-1){
         this.caculateSumAreaCountry();
      }else{
         this.areaList = res.areaData;
         this.countryList = res.countryData;
      }
   
      this.tableShow = true;
    },
    //合并单元格
    sgtableSpan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && rowIndex === 0) {
        return [12, 1];
      } else if (columnIndex == 0) {
        return [12, 0];
      }
    },
    getSGSummaries(param) {
      this.tableCaculate = param;
      const { columns, data } = param;
      const sums = [];

      let noCaculatColumn = [1, 2, 3, 4,5,6,7,8,9];
      columns.forEach((column, index) => {
        if (noCaculatColumn.findIndex(e => e == index) > -1) {
          sums[index] = "";
          return;
        }
        if (index === 0) {
          sums[index] = "全年汇总";
          return;
        }
         if (index === 10) {
          //铺货折扣
          let forecastDiscountSumVal =
            this.forecastDiscountSum / this.forecastSaleSum;
          if (!isNaN(forecastDiscountSumVal)) {
              sums[index] = this.convertThousandth(forecastDiscountSumVal ) ;
            this.newSGDiscountSum =
            this.convertThousandth(forecastDiscountSumVal);
          } else {
            sums[index] = "";
          }

          return;
        } 

        if (index === 11) {
          //MDR
          if (!isNaN(this.mdrSum/this.forecastSaleSum)) {
             sums[index] = this.convertThousandth(this.mdrSum/this.forecastSaleSum);
          } else {
            sums[index] = "";
          }

          return;
        }

        //mdr%    =  sum(mdr%*发票价*销量)/sum(销量*发票价)
        if (index === 12) {
          let mdrPercentSumVal = this.convertThousandth(this.mdrPercentSum/this.mdrPercentSumSaleBill)
          if (!isNaN(mdrPercentSumVal)) {
             sums[index] = mdrPercentSumVal+'%';  
          } else {
            sums[index] = "";
          }

          return;
        }

/*         if (index === 9) {
          //预测销量
          if (!isNaN(this.forecastSaleSum)) {
              sums[index] = this.convertDecimal(this.forecastSaleSum);
            this.newSGSalesSum = sums[index];
          } else {
            sums[index] = "";
          }

          return;
        } */

        //预算铺货折扣
        if (index == 17) {
          let budgetDiscoutSumVal = this.budgetDiscountSum / this.budgetSaleSum;
          if (!isNaN(budgetDiscoutSumVal)) {
            sums[index] = this.convertThousandth(budgetDiscoutSumVal);
          } else {
            sums[index] = "";
          }

          return;
        } 

        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = this.convertThousandth(this.convertDecimal(values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)));
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    caculateDiscountSum() {
      this.forecastDiscountSum = 0;
      this.forecastSaleSum = 0;
      //this.oldforecastDiscountSum = 0; //保存老值用于计算
      //this.oldforecastSaleSum = 0; //保存老值用于计算
      this.budgetDiscountSum = 0;
      this.budgetSaleSum = 0;
      this.mdrSum = 0;
      this.mdrPercentSum = 0;
      this.mdrPercentSumSaleBill = 0;

      //获取显示的值计算全部用request值，get数据时已做处理
      this.saleGroupList.forEach(e => {
         if (!!e.requestDiscount) {
          this.forecastDiscountSum =
            this.forecastDiscountSum + //+() 用于转换字符串为number
            (e.requestDiscount) *
              e.requestSales; //预测铺货折扣的汇总值为该区域各月铺货折扣 X各月销量
        }
        if (!!e.forecastDiscountPercent) {
          this.oldforecastDiscountSum =
            this.oldforecastDiscountSum +
            (+e.forecastDiscountPercent / 100) * e.forecastSales;
        } 
    /*     if (!!e.forecastSales) {
          this.oldforecastSaleSum = +this.oldforecastSaleSum + +e.forecastSales;
        } */

        if (!!e.requestSales) {
          this.forecastSaleSum = +this.forecastSaleSum + +e.requestSales; //+() 用于转换字符串为number
        }
        this.mdrSum = +this.mdrSum + (+e.requestMDR*e.requestSales); //+() 用于转换字符串为number
        //this.mdrPercentSum=this.mdrPercentSum+e.forecastMDRPercent
        
          this.mdrPercentSum=this.mdrPercentSum+e.forecastMDRPercent*e.forecastBillingPrice*e.requestSales
          this.mdrPercentSumSaleBill = this.mdrPercentSumSaleBill + e.requestSales * e.forecastBillingPrice
         

        
        


         if (!!e.budgetDiscountPercent) {
          this.budgetDiscountSum =
            this.budgetDiscountSum +
            (e.budgetDiscountPercent) * e.budgetSales; //预算铺货折扣的汇总值为该区域各月铺货折扣 X各月销量
        } 

         if (!!e.budgetSales) {
          this.budgetSaleSum = e.budgetSales + this.budgetSaleSum;
        } 
      });
    },
    async getLoginUserInfo() {
      const { result: res } = await this.$http.get("Home/GetUserInfo");
      if (!!res[0].loginId) {
        var userRoleIds = res[0].roleIds.split(";");
        this.userId = res[0].userId;
        var currentMenuId = window.sessionStorage.getItem("activeMenuId");
        if (userRoleIds.findIndex(e => e == 3) > -1) {
          //大区市场
          this.isLoginUserAsAreaMarket = true;
        }

        //判断是否有edit权限
        if (
          res[0].pageAccess.findIndex(
            e => e.menuId == currentMenuId && e.access == "modify"
          ) > -1
        ) {
          this.haveEditAccess = true;
        }
      }
    },
    getCurrentDate() {
      let date = new Date();
      this.currentYear = date.getFullYear(); //获取完整的年份(4位)
      this.currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      this.searchForm.year = this.currentYear;
    },
    edit() {
        this.inputDisable = false;
        if (searchForm.year > currentYear) {
            this.currentMonth = 1;
        }
    },
    inputChange(row) {
      row.isAdjust =
        row.requestDiscount !=
          row.forecastDiscountPercent * row.forecastBillingPrice/100 ||
        row.requestMDR != row.forecastMDR ||
        row.requestSales != row.forecastSales
          ? true
          : false;
   /*    if (  row.isAdjust == true)
       { */
        let forecastNetPrice =this.convertDecimal(
          row.forecastBillingPrice *
          (1 -
            row.requestDiscount / row.forecastBillingPrice  -
            row.forecastOtherDiscount / 100)); // 预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣))
        row.forecastNetSales =
           this.convertDecimal(forecastNetPrice * row.requestSales); //预测-净销售 = 预测-净价格 X 预测-销量
          row.netSalesVS = this.convertDecimal(row.forecastNetSales - row.budgetNetSales);
        row.forecastProfit =
            this.convertDecimal(
            (forecastNetPrice - row.forecastCost) * row.requestSales 
              ); //【预测利润】=（【预测净价格】-【成本】）*【预测销量】
          row.profitVS = this.convertDecimal(row.forecastProfit - row.budgetProfit);
          row.forecastMDRPercent =
            this.convertDecimal((row.requestMDR / row.forecastBillingPrice) * 100);
          
        row.forecastMDRAmount =
           this.convertDecimal(row.requestSales * row.requestMDR);

        this.caculateDiscountSum();
        this.getSGSummaries(this.tableCaculate);
        this.caculateSumAreaCountry();
     /*  } */
    },
    importDialogClosed() {
      this.$refs.importFormRef.resetFields();
    },
    async submitUpload(param) {
      let file = this.$refs.upload.uploadFiles.pop().raw; //这里获取上传的文件对象
      let formData = new FormData();
      formData.append("file", file);
      formData.append("requestId", this.requestId);
      const { result: res } = await this.$http.post(
        "/Forecast/uploadApplicationAttach",
        formData
      );

      if (res.fileId >= 0) {
        this.importDialogVisible = false;
        this.attachFileIdList.push(res.fileId);
        this.attachList.push({
          fileName: res.fileName,
          uploadTime: res.uploadTime
        });
        return this.$message.success("上传成功");
      } else if (res.errorType) {
        return this.$message.error("上传失败：" + res.errorType);
      } else {
        return this.$message.error("上传失败");
      }
    },
    //从申请记录页面来执行以下方法获取数据
      async checkifFromRecordPage() {
          console.log("in");
        if (!!this.$route.query.requestId) {
            this.searchForm.productName = this.$route.query.productName;
            this.searchForm.saleGroupId = this.$route.query.saleGroupId + "";
            this.searchForm.year = this.$route.query.year;
            if (this.$route.query.fromApprover == "true") {
                //表示来自审批人
                this.ifFromApprover = true;
                console.log(this.ifFromApprover);
            }
            else {
                this.ifFromApprover = false;
            }
        this.checkIfWaitingApprove();
        this.saleGroupList = [];
        const { result: res } = await this.$http.get(
            `/Forecast/GetDiscountsByRequestId?requestId=${this.$route.query.requestId}`
        );
        const { result: monthList } = await this.$http.get(
          "/Home/GetSelectList?lookup=NormalMonth"
        );
        this.normalMonthList = monthList;

        this.workFlowStepId = res.saleGroupData[0].workFlowStepId; //step >0 表示待审批 不可以再提交或保存草稿

        const { result: attachList } = await this.$http.get(
          `/Forecast/GetRequestAttachments?requestId=${res.saleGroupData[0].requestId}`
        );
        this.attachList = attachList;
        this.attachFileIdList = [];
        attachList.forEach(e => this.attachFileIdList.push(e.fileId));

        for (let i = 1; i <= 12; i++) {
          if (res.saleGroupData.findIndex(e => e.month == i) > -1) {
            let index = res.saleGroupData.findIndex(e => e.month == i);
            res.saleGroupData[index].monthLabel = this.normalMonthList.find(
              x => x.value == i
            ).label;
          } else {
            let obj = Object.assign({}, this.sgTemplateObj);
            obj.month = +this.normalMonthList.find(x => x.value == i).value;
            obj.monthLabel = this.normalMonthList.find(y => y.value == i).label;
            res.saleGroupData.push(obj);
          }
        }

        res.saleGroupData.forEach(x => {
          //x.oldforecastNetSales = x.forecastNetSales;
          //x.oldforecastProfit = x.forecastProfit;
          x.requestDiscount = this.convertDecimal(x.requestDiscount)
          x.requestMDR = this.convertDecimal(x.requestMDR)
          if (x.isAdjust == true) {
            let forecastNetPrice =this.convertDecimal(
              x.forecastBillingPrice *
              (1 -
                x.requestDiscount / x.forecastBillingPrice   -
                x.forecastOtherDiscount / 100)); // 预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣))
                x.forecastNetSales =
                  this.convertDecimal(forecastNetPrice * x.requestSales); //预测-净销售 = 预测-净价格 X 预测-销量
              x.netSalesVS = this.convertDecimal(x.forecastNetSales - x.budgetNetSales);
            x.forecastProfit =
               this.convertDecimal(
                (forecastNetPrice - x.forecastCost) * x.requestSales
                  ); //【预测利润】=（【预测净价格】-【成本】）*【预测销量】
              x.profitVS = this.convertDecimal(x.forecastProfit - x.budgetProfit);
              x.forecastMDRPercent = this.convertDecimal(x.requestMDR/x.forecastBillingPrice*100);
              x.forecastMDRAmount = this.convertDecimal(x.requestSales * x.requestMDR);
          }
        });

        res.saleGroupData.sort((a, b) => a.month - b.month);

        this.saleGroupList = res.saleGroupData;
        this.reason = res.reason;
        this.caculateDiscountSum();

        if(this.saleGroupList.findIndex(e=>e.isAdjust==true)>-1){
         this.caculateSumAreaCountry();
        }else{
         this.areaList = res.areaData;
         this.countryList = res.countryData;
        }
        
        this.tableShow = true;
      }
    },
      async saveDraft() {

          console.log("insaveDraft");
          if (this.isClosed == 1) {
              return this.$message.warning("申请单已被关闭，无法重新提交。");
          };
          console.log("saveDraft isClosed" + this.isClosed);
          if (this.IsReject == 1) {
              if (this.isClosed == 0) {
                  const confirmResult = await this.$confirm('当前申请已被拒绝，是否重新保存为新的草稿?', '', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                  }).catch(err => err)
                  if (confirmResult !== 'confirm') {
                      return this.$message.warning("取消操作。");
                  }
                  else {
                      console.log("重新草稿");
                      //重新提交一个新的申请单
                      let adjustRequestList = [];
                      this.saleGroupList
                          .filter(e => e.isAdjust == true)
                          .forEach(i => {
                              adjustRequestList.push({
                                  forecastId: i.forecastId,
                                  reason: this.reason,
                                  requestId: 0,
                                  productId: i.productId,
                                  year: i.year,
                                  areaId: i.areaId,
                                  saleGroupId: i.saleGroupId,
                                  userId: this.userId,
                                  statusId: 0,
                                  month: i.month,
                                  discount: i.requestDiscount, //全部使用request值， 在get数据时已经做过处理
                                  mdr: i.requestMDR, //全部使用request值， 在get数据时已经做过处理
                                  sales: i.requestSales //全部使用request值， 在get数据时已经做过处理
                              });
                          });
                      if (adjustRequestList.length == 0) {
                          return this.$message.warning("没有任何数据调整，无法提交审批");
                      };
                      this.fileIdListStr = this.attachFileIdList.join(";");
                      const { result: res } = await this.$http.fetchData(
                          "/Forecast/SaveRequest",
                          {
                              requestData: adjustRequestList,
                              fileIds: this.fileIdListStr
                          }
                      );
                      if (res == "success") {
                          this.$message.success("提交审批成功");
                          this.$router.push({
                              name: "applicationrecord",
                              params: {
                              }
                          });
                          window.open(pathInfo.href, '_blank');
                      } else {
                          this.$message.error("提交审批失败");
                      }
                  }
              }
          }
          else {


              if (this.workFlowStepId > 0) {
                  return this.$message.warning("当前申请已经提交审批，不可保存草稿");
              }
              let adjustRequestList = [];
              this.saleGroupList
                  .filter(e => e.isAdjust == true)
                  .forEach(i => {
                      if (i.requestId != 0) {
                          adjustRequestList.push({
                              forecastId: i.forecastId,
                              reason: this.reason,
                              requestId: i.requestId,
                              productId: i.productId,
                              year: i.year,
                              areaId: i.areaId,
                              saleGroupId: i.saleGroupId,
                              userId: this.userId,
                              statusId: 0, // 0  是保存草稿 ， 1 是提交审批
                              month: i.month,
                              discount: i.requestDiscount,
                              mdr: i.requestMDR,
                              sales: i.requestSales
                          });
                      } else {
                          adjustRequestList.push({
                              forecastId: i.forecastId,
                              reason: this.reason,
                              requestId: i.requestId,
                              productId: i.productId,
                              year: i.year,
                              areaId: i.areaId,
                              saleGroupId: i.saleGroupId,
                              userId: this.userId,
                              statusId: 0,
                              month: i.month,
                              discount: i.requestDiscount, //全部使用request值， 在get数据时已经做过处理
                              mdr: i.requestMDR, //全部使用request值， 在get数据时已经做过处理
                              sales: i.requestSales //全部使用request值， 在get数据时已经做过处理
                          });
                      }
                  });
              if (adjustRequestList.length == 0) {
                  return this.$message.warning("没有任何数据调整，无法保存草稿");
              }
              this.fileIdListStr = this.attachFileIdList.join(";");
              const { result: res } = await this.$http.fetchData(
                  "/Forecast/SaveRequest",
                  {
                      requestData: adjustRequestList,
                      fileIds: this.fileIdListStr
                  }
              );

              if (res == "success") {
                  this.$message.success("保存草稿成功");
                  if (!!this.$route.params.requestId) {
                      this.checkifFromRecordPage();
                  } else {
                      this.getSaleGroup();
                  }
              } else {
                  this.$message.error("保存草稿失败");
              }
          }
    },
      async saveCommit() {
          console.log("insavecommint");
          if (this.isClosed == 1) {
              return this.$message.warning("申请单已被关闭，无法重新提交。");
          };
          console.log("saveCommit isClosed" + this.isClosed);

        //if reject and not close
          if (this.IsReject == 1) {
              if (this.isClosed == 0) {
                  const confirmResult = await this.$confirm('当前申请已被拒绝，是否重新提交申请?', '', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                  }).catch(err => err)
                  if (confirmResult !== 'confirm') {
                      return this.$message.warning("取消操作。");
                  }
                  else {
                      console.log("重新提交");
                      //重新提交一个新的申请单
                      let adjustRequestList = [];
                      this.saleGroupList
                          .filter(e => e.isAdjust == true)
                          .forEach(i => {                              
                                  adjustRequestList.push({
                                      forecastId: i.forecastId,
                                      reason: this.reason,
                                      requestId: 0,
                                      productId: i.productId,
                                      year: i.year,
                                      areaId: i.areaId,
                                      saleGroupId: i.saleGroupId,
                                      userId: this.userId,
                                      statusId: 1,
                                      month: i.month,
                                      discount: i.requestDiscount, //全部使用request值， 在get数据时已经做过处理
                                      mdr: i.requestMDR, //全部使用request值， 在get数据时已经做过处理
                                      sales: i.requestSales //全部使用request值， 在get数据时已经做过处理
                                  });
                          });
                      if (adjustRequestList.length == 0) {
                          return this.$message.warning("没有任何数据调整，无法提交审批");
                      };
                      this.fileIdListStr = this.attachFileIdList.join(";");
                      const { result: res } = await this.$http.fetchData(
                          "/Forecast/SaveRequest",
                          {
                              requestData: adjustRequestList,
                              fileIds: this.fileIdListStr
                          }
                      );
                      if (res == "success") {
                          this.$message.success("提交审批成功");
                          this.$router.push({
                              name: "applicationrecord",
                              params: {
                              }
                          });
                          window.open(pathInfo.href, '_blank');                         
                      } else {
                          this.$message.error("提交审批失败");
                      }
                  }
              }
          }
          else {

              if (this.workFlowStepId > 0) {
                  return this.$message.warning("当前申请已经提交审批，不可再提交");
              }
              let adjustRequestList = [];
              this.saleGroupList
                  .filter(e => e.isAdjust == true)
                  .forEach(i => {
                      if (i.requestId != 0) {
                          adjustRequestList.push({
                              forecastId: i.forecastId,
                              reason: this.reason,
                              requestId: i.requestId,
                              productId: i.productId,
                              year: i.year,
                              areaId: i.areaId,
                              saleGroupId: i.saleGroupId,
                              userId: this.userId,
                              statusId: 1,
                              month: i.month,
                              discount: i.requestDiscount,
                              mdr: i.requestMDR,
                              sales: i.requestSales
                          });
                      } else {
                          adjustRequestList.push({
                              forecastId: i.forecastId,
                              reason: this.reason,
                              requestId: i.requestId,
                              productId: i.productId,
                              year: i.year,
                              areaId: i.areaId,
                              saleGroupId: i.saleGroupId,
                              userId: this.userId,
                              statusId: 1,
                              month: i.month,
                              discount: i.requestDiscount, //全部使用request值， 在get数据时已经做过处理
                              mdr: i.requestMDR, //全部使用request值， 在get数据时已经做过处理
                              sales: i.requestSales //全部使用request值， 在get数据时已经做过处理
                          });
                      }
                  });
              if (adjustRequestList.length == 0) {
                  return this.$message.warning("没有任何数据调整，无法提交审批");
              }
              this.fileIdListStr = this.attachFileIdList.join(";");
              const { result: res } = await this.$http.fetchData(
                  "/Forecast/SaveRequest",
                  {
                      requestData: adjustRequestList,
                      fileIds: this.fileIdListStr
                  }
              );

              if (res == "success") {
                  if (!!this.$route.params.requestId) {
                      this.checkifFromRecordPage();
                  } else {
                      this.getSaleGroup();
                  }
                  this.$message.success("提交审批成功");
              } else {
                  this.$message.error("提交审批失败");
              }
          }
    },
    async rejectRequest() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/ApproveRejectRequest",
        {
          roleId: this.$route.params.opRoleId,
          requestId: this.$route.params.requestId,
          comment: this.approveRejectReason,
          op: "reject"
        }
      );
      if (res == "success") {
        this.$message.success("拒绝成功");
        this.checkIfWaitingApprove();
      } else {
        this.$message.error("拒绝失败");
      }
    },
    async approveRequest() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/ApproveRejectRequest",
        {
          roleId: this.$route.params.opRoleId,
          requestId: this.$route.params.requestId,
          comment: this.approveRejectReason,
          op: "approve"
        }
      );
      if (res == "success") {
        this.$message.success("批准成功");
        this.checkIfWaitingApprove();
        this.$router.push({
        name: "applicationrecord",
        params: {
        }});
      } else {
        this.$message.error("批准失败");
      }
    },
    async downLoadAttachedFile(fileId, fileName) {
      const res = await this.$http.downLoad(
        `/Forecast/GetFileByFileId?fileId=${fileId}`
      );

      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      var objectUrl = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("style", "display:none");
      a.setAttribute("href", objectUrl);
      a.setAttribute("download", `${fileName}.xlsx`);
      a.click();
      URL.revokeObjectURL(objectUrl);
    },
    async caculateSumAreaCountry() {
      //现在采用调用后台的方式重新计算大区和全国的值
      if(this.saleGroupList.findIndex(e=>e.isAdjust==true)>-1){  //判断是否有调整过的，如果有重新获取后台计算
         const { result: res } = await this.$http.fetchData("/Forecast/ReCalculate",
         { data: this.saleGroupList.filter(i=>i.forecastId!=0)}  );
         this.areaList = res.areaData;
         this.countryList = res.countryData;
      }
      /* 
      //计算大区和全国的数据
      let isAdjustCount = this.saleGroupList.filter(e => e.isAdjust == true)
        .length;
      if (isAdjustCount != 0) {
        let sumSale = 0;
        let sumNetSale = 0;
        let sumProfit = 0;
        this.saleGroupList.forEach(e => {
          if (e.isAdjust == true) {
            sumSale = sumSale + (e.requestSales - e.forecastSales);
            sumNetSale =
              sumNetSale + (e.forecastNetSales - e.oldforecastNetSales);
            sumProfit = sumProfit + (e.forecastProfit - e.oldforecastProfit);
          }
        });
        //  = sum(小区数据（修改为是）-老的小区数据) + 后台得到的老的汇总数据
        this.areaList[0].forecastSales =
          sumSale + this.areaList[0].forecastSales;
        this.areaList[0].forecastNetSales =
          sumNetSale + this.areaList[0].forecastNetSales;
        this.areaList[0].forecastProfit =
          sumProfit + this.areaList[0].forecastProfit;

        this.countryList[0].forecastSales =
          sumSale + this.countryList[0].forecastSales;
        this.countryList[0].forecastNetSales =
          sumNetSale + this.countryList[0].forecastNetSales;
        this.countryList[0].forecastProfit =
          sumProfit + this.countryList[0].forecastProfit;

        //其他小区总量 = 老铺货折扣 * 老的预测销量- 老的小区全年汇总铺货折扣*老的小区全年汇总销量
        let otherSum =
          (this.areaList[0].forecastDiscountPercent / 100) *
            this.areaList[0].forecastSales -
          (this.oldforecastDiscountSum / this.oldforecastSaleSum) *
            this.oldforecastSaleSum;
        // 汇总预测铺货折扣 = （新的小区全年总铺货折扣*新小区全年汇总销量+其他小区总量）/新预测销量
          let newAreaforecastDiscountPercent =
              this.convertDecimal(
                  (this.newSGDiscountSum * this.newSGSalesSum + otherSum) /
                  this.areaList[0].forecastSales
              ); 
         

        this.areaList[0].forecastDiscountPercent = isNaN(
          newAreaforecastDiscountPercent
        )
          ? ""
          : newAreaforecastDiscountPercent;

        let otherSumCountry =
          (this.countryList[0].forecastDiscountPercent / 100) *
            this.countryList[0].forecastSales -
          (this.oldforecastDiscountSum / this.oldforecastSaleSum) *
            this.oldforecastSaleSum;

          let countryNewDiscount =
              this.convertDecimal(
                  (this.newSGDiscountSum * this.newSGSalesSum + otherSumCountry) /
                  this.countryList[0].forecastSales
              );
          

        this.countryList[0].forecastDiscountPercent = isNaN(countryNewDiscount)
          ? ""
          : countryNewDiscount;
      } */
    },
    keepDigitals(row, column) { 
        if (isNaN(row[column.property])||!row[column.property]) 
          return  row[column.property]; 
          else 
          return (Math.round(row[column.property]*100)/100).toFixed(2)
    },
      async checkIfWaitingApprove() {
        
          const { result: res } = await this.$http.fetchData(
              "/Forecast/GetDiscountRequestStatusById",
              {
                  requestId: this.$route.query.requestId,
              }
        
          );
      if(res.requests.length>0){
          this.isWaitingApprove = res.requests[0].isWaitingApproval;          
          this.isClosed = res.requests[0].isClosed;
          this.IsReject = res.requests[0].isReject;
          
      }
      else{
          this.isWaitingApprove = false;
          this.isClosed = false;
          this.IsReject = false;
        };
          console.log("is waiting approve--" + this.isWaitingApprove);
          console.log("isClosed--" + this.isClosed);
          console.log("IsReject--" + this.IsReject);
      },
      async closeRequest() {
          const { result: res } = await this.$http.fetchData(
              "/Forecast/closeRequest",
              {
                  requestId: this.$route.query.requestId,
              }
          );
          if (res == "success") {
              this.$message.success("申请单关闭成功");
              this.checkIfWaitingApprove();
          } else {
              this.$message.error("申请单关闭失败");
          }
      }
 
  }
};
</script>

<style lang="less" scoped>
.redMark {
  color: red;
}
.areaTable,
.countryTable {
  margin-top: 20px;
}
.reason {
  margin-top: 25px;
}
.attach {
  margin-top: 25px;
}
.attachName {
  color: #aab400;
  text-decoration-line: underline;
  cursor: pointer;
}
tr td {
  padding: 2px 0;
}
</style>