/* import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import User from '../components/user/User.vue'
import Area from '../components/area/Area.vue'
import ImportSale from '../components/forecast/ImportSale.vue'
import Log from '../components/log/Log.vue'
import Product from '../components/product/Product.vue'
import WorkFlow from '../components/workFlow/WorkFlow.vue'
import Budget from '../components/budget/Budget.vue'
import Distribution from '../components/forecast/Distribution.vue'
import AdjustApplication from '../components/management/AdjustApplication.vue'
import EffectReport from '../components/management/EffectReport.vue'
import ApplicationRecord from '../components/management/ApplicationRecord.vue'
import BudgetVsForecastReport from '../components/report/BudgetVsForecastReport.vue'
import NetPriceReport from '../components/report/NetPriceReport.vue'
import Error from '../components/Error.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: './home' },
    { path: '/home', component: Home, name: "home" },
    {
        path: '/home', component: Home,
        redirect: '/welcome',
        children: [
            { path: '/welcome', component: Welcome },
            { path: '/user', component: User },
            { path: '/area', component: Area },
            { path: '/product', component: Product },
            { path: '/log', component: Log },
            { path: '/importSale', component: ImportSale },
            { path: '/workflow', component: WorkFlow },
            { path: '/budget', component: Budget },
            { path: '/distribution', component: Distribution },
            { path: '/adjustapplication', name: 'adjustapplication', component: AdjustApplication },
            { path: '/effectreport', component: EffectReport },
            { path: '/applicationrecord',name: 'applicationrecord', component: ApplicationRecord },
            { path: '/budgetvsforecast', component: BudgetVsForecastReport },
            { path: '/netprice', component: NetPriceReport }
        ]
    },
    { path: '/error', component: Error, name: "error" }
]

const router = new VueRouter({
    /*  mode: 'history',
     base: process.env.BASE_URL, */
    routes
})


router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/home') > -1 || to.path.indexOf('/Home') > -1) return next()
    if (to.path.indexOf('/error') > -1 || to.path.indexOf('/Error') > -1) return next()
    const tokenStr = window.sessionStorage.getItem('dmstoken')
    if (!tokenStr){   //新窗口首次进入系统
        window.sessionStorage.setItem("firstFromURL",to.path); //记录首次进入系统的路由
        return next('/home')
    }
    next()
})

export default router


