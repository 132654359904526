<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>净价格报表</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card class="search-item" shadow="never">
            <span>搜索条件</span>
        </el-card>

        <el-card class="search-form" shadow="never">
            <!-- 搜索表单 -->
            <el-form ref="searchFormRef">
                <el-row>
                    <el-col :span="5">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item prop="productName">
                                    <label>
                                        <span class="red">*</span>年份
                                    </label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="15">
                                <el-form-item>
                                    <el-select v-model="searchList.year"
                                               filterable
                                               placeholder
                                               @change="handleSelectChange">
                                        <el-option v-for="item in yearDDL"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="16" :offset="3">
                        <el-row>
                            <el-col :span="3">
                                <el-form-item>
                                    <el-button type="primary" @click="getReport">查询</el-button>
                                </el-form-item>
                            </el-col>

                            <el-col :span="3" :offset="1">
                                <el-form-item>
                                    <el-button type="primary" @click="exportReportOnline">实时导出</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" :offset="1">
                                <el-form-item>
                                    <el-button type="primary" @click="exportReport">邮件通知导出</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card class="search-item" shadow="never">
            <span>查询结果</span>
        </el-card>

        <el-card class="result-table" shadow="never" v-show="tableShow">
            <el-table :data="reportList" border stripe>
                <el-table-column prop="agiCodeNo" label="产品代码"></el-table-column>
                <el-table-column prop="netpriceWhole" :formatter='keepDigitals' label="全国加权平均净价格">
                    <template v-slot="scope">
                        {{scope.row.netpriceWhole|thousandth}}
                    </template>                      
                </el-table-column>
                <el-table-column prop="areaName" label="大区"></el-table-column>
                <el-table-column prop="netPriceArea" :formatter='keepDigitals' label="大区加权平均净价格">
                    <template v-slot="scope">
                        {{scope.row.netPriceArea|thousandth}}
                    </template>                        
                </el-table-column>
                <el-table-column prop="saleGroupName" label="小区"></el-table-column>
                <el-table-column prop="netPriceCompare" :formatter='keepDigitals' label="小区加权平均净价格">
                    <template v-slot="scope">
                        {{scope.row.netPriceCompare|thousandth}}
                    </template>                       
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="queryInfo.pagenum"
                           :page-sizes="[10, 20, 50]"
                           :page-size="queryInfo.pagesize"
                           layout="total, sizes, prev, pager, next, jumper"
                           :hide-on-single-page="true"
                           :total="listTotalNum"></el-pagination>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchList: { year: null },
                yearDDL: [],
                tableShow: false,
                queryInfo: {
                    query: "",
                    pagenum: 1,
                    pagesize: 10
                },
                reportList: [],
                listTotalNum: null,
                currentYear: null
            };
        },
        created() {
            this.getDDL();
            this.getCurrentDate();
        },
        methods: {
            async getDDL() {
                const { result: year } = await this.$http.get(
                    "/Home/GetSelectList?lookup=NormalYear"
                );

                this.yearDDL = year;
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
            },
            keepDigitals(row, column) { 
                if (isNaN(row[column.property])) 
                    return row[column.property]; 
                else 
                    return (Math.round(row[column.property]*100)/100).toFixed(2)
            }, 
            handleCurrentChange(newPage) {
                this.queryInfo.pagenum = newPage;
                this.getReport()
            },
            async getReport() {
                if (this.searchList.year == null) {
                    return this.$message.warning("请选择年份");
                }
                const { result: res } = await this.$http.fetchData(
                    "Forecast/GetNetPriceReport",
                    {
                        year: this.searchList.year,
                        pageNumber: this.queryInfo.pagenum - 1,
                        pageSize: this.queryInfo.pagesize,
                        isExport: false,
                        isStored: false,
                    }
                );
                if (!!res.data.length) {
                    this.reportList = res.data;              
                    this.listTotalNum = res.total;
                    this.tableShow = true;
                } else {
                    this.tableShow = false;
                    this.$message.warning("没有对应的搜索数据");
                }
            },
            resetSeach() {
                this.searchList = {};
            },
            async exportReport() {
                if (this.searchList.year == null) {
                    return this.$message.warning("请选择年份");
                }
                const { result: fileName } = await this.$http.fetchData(
                    "Forecast/GetNetPriceReport",
                    {
                        year: this.searchList.year,
                        pageNumber: this.queryInfo.pagenum - 1,
                        pageSize: this.queryInfo.pagesize,
                        isExport: true,
                        isStored: true,
                    }
                );
                if (fileName == "success") {
                    return this.$message.success("导出请求已被记录，后面会以邮件形式通知下载。");
                } else {
                    return this.$message.warning(fileName);
                }
            },
            async exportReportOnline() {
                if (this.searchList.year == null) {
                    return this.$message.warning("请选择年份");
                }
                const { result: fileName } = await this.$http.fetchData(
                    "Forecast/GetNetPriceReport",
                    {
                        year: this.searchList.year,
                        pageNumber: this.queryInfo.pagenum - 1,
                        pageSize: this.queryInfo.pagesize,
                        isExport: true,
                        isStored: false,
                    }
                );
                if (fileName == "success") {
                    return this.$message.success("导出请求已被记录，后面会以邮件形式通知下载。");
                } else {
                    this.downloadFileName = fileName;
                    var todayDate = new Date();
                    const res = await this.$http.downLoad(
                        `/Home/Download?fileName=${this.downloadFileName}`
                    );
                    var blob = new Blob([res.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    var objectUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.setAttribute("style", "display:none");
                    a.setAttribute("href", objectUrl);
                    a.setAttribute(
                        "download",
                        `净价格报表_${todayDate.getFullYear()}${todayDate.getMonth() +
                        1}${todayDate.getDate()}.xlsx`
                    );
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                }
            },

            getCurrentDate() {
                let date = new Date();
                this.currentYear = date.getFullYear(); //获取完整的年份(4位)
                this.searchList.year = this.currentYear;
            },
            handleSelectChange() {
                this.$forceUpdate();
            }
        }
    };
</script>
<style lang="less" scoped>
    .red {
        color: red;
    }
</style>