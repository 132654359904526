<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>品牌</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.brand" filterable placeholder>
                    <el-option
                      v-for="item in brandDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品名</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.productName" filterable placeholder>
                    <el-option
                      v-for="item in productNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品线</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.productLine" filterable placeholder>
                    <el-option
                      v-for="item in productLineDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>是否特殊</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.isSpecial" filterable placeholder>
                    <el-option
                      v-for="item in isSpecialProductDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品经理</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.productManageUserId" filterable placeholder>
                    <el-option
                      v-for="item in productManagerDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品线经理</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.productLineManangeUserId" filterable placeholder>
                    <el-option
                      v-for="item in productLineManangerDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-row>
                <el-col :span="7">
                  <label>产品代码</label>
                </el-col>
                <el-col :span="13">
                  <el-select v-model="searchList.agicode" filterable placeholder>
                    <el-option
                      v-for="item in agicodeDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row>
              <el-form-item>
                <el-col :span="10">
                  <el-button type="primary" @click="getProductList">查询</el-button>
                </el-col>
                <el-col :span="10">
                  <el-button type="primary" @click="resetSearch">重置</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>

    <el-card class="result-table" shadow="never">
      <el-row>
        <el-col :span="18">
          <h3 class="title">产品列表</h3>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="10">
              <el-button class="btn addBtn" type="primary" @click="showAddDialog">新增</el-button>
            </el-col>
            <!--             <el-col :span="10">
              <el-button
                class="btn addBtn importSpecialBtn"
                type="primary"
                @click="showImportSpecialDialog"
              >导入特殊产品</el-button>
            </el-col>-->
          </el-row>
        </el-col>
      </el-row>
      <el-table :data="productList" border stripe height="500">
        <el-table-column prop="productId" label="产品ID" v-if="productIdShow"></el-table-column>
        <el-table-column prop="productBrand" label="品牌"></el-table-column>
        <el-table-column prop="productSize" label="规格"></el-table-column>
        <el-table-column prop="productName" label="产品名"></el-table-column>
        <el-table-column prop="productLine" label="产品线"></el-table-column>
        <el-table-column prop="productManangerName" label="产品经理"></el-table-column>
        <el-table-column prop="productlineManangerName" label="产品线经理"></el-table-column>
        <el-table-column
          v-for="item in agiIteratorList"
          :key="item"
          :label="'产品代码'+ String.fromCharCode(64+item)"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.agicodeList[item-1]? scope.row.agicodeList[item-1].agicodeNo:""}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="productIsSpecial" label="是否特殊">
          <template slot-scope="scope">
            <span v-if="scope.row.productIsSpecial === true">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改产品"
              :enterable="false"
              placement="top"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                circle
                @click="showEditDialog(scope.row.productId)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改产品的对话框 -->
    <el-dialog title="修改产品信息" :visible.sync="editDialogVisible" width="40%" :close-on-click-modal='false'>
      <el-form
        :model="editForm"
        label-width="150px"
        ref="editFormRef"
        class="editForm"
        :label-position="'left'"
      >
        <el-form-item label="品牌">
          <el-input v-model="editForm.productBrand"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="editForm.productSize"></el-input>
        </el-form-item>
        <el-form-item label="产品名">
          <el-input v-model="editForm.productName"></el-input>
        </el-form-item>
        <el-form-item label="产品线">
          <el-input v-model="editForm.productLine"></el-input>
        </el-form-item>
        <el-form-item label="产品经理">
          <el-select v-model="editForm.productManangerId" filterable placeholder>
            <el-option
              v-for="item in productManagerDDL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品线经理">
          <el-select v-model="editForm.productlineManangerId" filterable placeholder>
            <el-option
              v-for="item in productLineManangerDDL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="item in editAgiIteratorList"
          :key="item"
          :label="'产品代码'+ String.fromCharCode(64+item)"
        >
          <el-input v-model="editForm.agicodeList[item-1].agicodeNo"></el-input>
        </el-form-item>
        <el-form-item label ="是否特殊">
            <el-switch  v-model="editForm.productIsSpecial"
                       active-color="#AAB400" 
                       active-text="是"
            inactive-text="否">
            </el-switch>
       </el-form-item>
</el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateProduct">保存</el-button>
      </span>
    </el-dialog>

    <!-- 新增产品的对话框 -->
    <el-dialog title="新增产品信息" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed" :close-on-click-modal='false'>
      <el-form
        :model="addForm"
        label-width="200px"
        ref="addFormRef"
        class="addForm"
        :label-position="'left'"
        :rules="addProductRules"
      >
        <el-form-item label="品牌" prop="productBrand">
          <el-input v-model="addForm.productBrand"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="productSize">
          <el-input v-model="addForm.productSize"></el-input>
        </el-form-item>
        <el-form-item label="产品名" prop="productName">
          <el-input v-model="addForm.productName"></el-input>
        </el-form-item>
        <el-form-item label="产品线" prop="productLine">
          <el-input v-model="addForm.productLine"></el-input>
        </el-form-item>
        <el-form-item label="产品经理" prop="productManangerId">
          <el-select v-model="addForm.productManangerId" filterable placeholder>
            <el-option
              v-for="item in productManagerDDL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品线经理" prop="productlineManangerId">
          <el-select v-model="addForm.productlineManangerId" filterable placeholder>
            <el-option
              v-for="item in productLineManangerDDL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in addForm.agicodeList"
          :key="'agi'+index"
          :label="'产品代码'+ String.fromCharCode(65+index)"
          :class="item.class"
        >
          <el-input v-model="addForm.agicodeList[index].agicodeNo"></el-input>
        </el-form-item>

        <el-form-item label="预算基本折扣%" prop="discount.basicDiscount" class="agisfromSecond">
          <el-input v-model="addForm.discount.basicDiscount" suffix-icon="iconfont icon-percent"></el-input>
        </el-form-item>
        <el-form-item label="预算发票价" prop="discount.fapiaoPrice" >
          <el-input v-model="addForm.discount.fapiaoPrice" @change="addProductInputChange"></el-input>
        </el-form-item>
        <el-form-item label="预算铺货折扣%" prop="discount.distirbutionDiscount" class="agisfromSecond">
          <el-input
            v-model="addForm.discount.distirbutionDiscount"
            suffix-icon="iconfont icon-percent"
            @change="addProductInputChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="预算成本" prop="discount.cost" >
          <el-input v-model="addForm.discount.cost"></el-input>
        </el-form-item>
        <el-form-item label="预算净价格" prop="discount.netPrice" class="agisfromSecond">
          <el-input v-model="addForm.discount.netPrice"></el-input>
        </el-form-item>

        <!--        <el-form-item
          v-for="(item,i) in otherDiscountIteratorList"
          :key="item.oDiscountId"
          :label="item.oDiscountName"
        >
          <el-input v-model="addForm.otherDiscountList[i].oDiscountValue"></el-input>
        </el-form-item>-->

        <el-form-item
          v-for="(item,i) in addForm.otherDiscountList"
          :key="item.oDiscountId"
          :label="item.oDiscountName"
          class="agisfromSecond"
        >
          <el-input
            v-model="addForm.otherDiscountList[i].oDiscountValue"
            :suffix-icon="item.isPercentClass"
            @change="addProductInputChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否特殊" class="agisfromSecond">
          <el-switch
            style="display: block"
            v-model="addForm.productIsSpecial"
            active-color="#AAB400"
            active-text="是"
            inactive-text="否"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addProduct">保存</el-button>
      </span>
    </el-dialog>

    <!-- 导入对话框 -->
    <!--     <el-dialog title="导入特殊产品" :visible.sync="importSpecialProductVisible" width="40%">
      <el-form ref="importSpecialFormRef" label-width="100px">
        <el-form-item label="上传文件：">
          <el-upload
            class="upload"
            ref="upload"
            action
            :file-list="fileList"
            :multiple="false"
            :show-file-list="true"
            :limit="1"
            :auto-upload="false"
            accept=".xlsx"
          >
            <el-button slot="trigger" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传一个文件，需要先删除已选择的文件，然后重新选择</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importSpecialProductVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUploadSpecial">确定上传</el-button>
      </span>
    </el-dialog>-->
  </div>
</template>


<script>
export default {
  data() {
    var checkNumber = (rule, value, cb) => {
      const regNumber = /^-?\d+\.?\d{0,2}$/; //最多两位小数
      if(!!rule.required&&!value){
        cb(new Error("请输入数字并且小数位数不超过2！"));
      }
      else if (!value) {
        return cb();
      } else if (regNumber.test(value)) {
        return cb();
      }
      cb(new Error("请输入数字并且小数位数不超过2！"));
    };
    var checkEmpty = (rule, value, cb) => {
      if (!!value) {
        return cb();
      }
      cb(new Error("请选择！"));
    };
    return {
      productList: [],
      productIdShow: false,
      searchList: {
        productId: 0,
        brand: null,
        productName: "",
        productLine: "",
        productManageUserId: null,
        productLineManangeUserId: null,
        agicode: "",
        isSpecial: 2
      },
      brandDDL: [],
      productNameDDL: [],
      productLineDDL: [],
      productManagerDDL: [],
      productLineManangerDDL: [],
      agicodeDDL: [],
      editDialogVisible: false,
      editForm: {},
      agiListMaxLength: 0,
      agiIteratorList: [],
      sysAgiMaxVal: 0,
      editAgiIteratorList: [],
      addDialogVisible: false,
      addForm: {
        productId: 0,
        productBrand: null,
        productSize: null,
        productName: null,
        productLine: null,
        productManangerId: null,
        productlineManangerId: undefined,
        agicodeList: [],
        discount: {},
        otherDiscountList: [{ oDiscountId: 0, oDiscountValue: null }],
        isSpecial: false
      },
      addAgiIteratorList: [],
      otherDiscountIteratorList: [],
      isSpecialProductDDL: [
        { value: 2, label: " " },
        { value: 0, label: "否" },
        { value: 1, label: "是" }
      ],
      importSpecialProductVisible: false,
      fileList: [],
      addProductRules: {
        productBrand: [
          { required: true, message: "请输入品牌名", trigger: "blur" }
        ],
        productSize: [
          { required: true, message: "请输入规格", trigger: "blur" }
        ],
        productName: [
          { required: true, message: "请输入产品名", trigger: "blur" }
        ],
        productLine: [
          { required: true, message: "请输入产品线", trigger: "blur" }
        ],
        productManangerId: [
          {
            required: true,
            message: "请选择产品经理",
            trigger: ["change", "blur"]
          }
        ],
        productlineManangerId: [
          {
            required: true,
            message: "请选择产品线经理",
            trigger: ["blur", "change"]
          }
        ],
        "discount.basicDiscount": [
          {
            validator: checkNumber,
            trigger: "change"
          }
        ],
        "discount.fapiaoPrice": [{ required: true, message: "请输入发票价",validator: checkNumber, trigger: ["blur", "change"] }],
        "discount.distirbutionDiscount": [
          { validator: checkNumber, trigger: "change" }
        ],
        "discount.cost": [{ required: true,message: "请输入成本",validator: checkNumber, trigger: ["blur", "change"] }],
        "discount.netPrice": [{ validator: checkNumber, trigger: "change" }]
      }
    };
  },
  created() {
    this.getAllDDL();
    this.getProductList();
  },
  methods: {
    async getProductList() {
      const { result: res } = await this.$http.fetchData(
        "Product/GetProductList",
        {
          product_id: this.searchList.productId,
          brand: this.searchList.brand,
          productName: this.searchList.productName,
          productLine: this.searchList.productLine,
          productManageUserId: this.searchList.productManageUserId,
          productLineManangeUserId: this.searchList.productLineManangeUserId,
          agicode: this.searchList.agicode,
          productIsSpecial: this.searchList.isSpecial
        }
      );
      if (res.productList.length > 0) {
        this.productList = res.productList;
        this.agiListMaxLength = res.agiMaxLength;
        this.agiIteratorList = [];
        for (let i = 0; i < this.agiListMaxLength; i++) {
          this.agiIteratorList.push(i + 1);
        }
      } else {
        this.productList = [];
        this.$message.warning("没有对应的搜索数据");
      }
    },
    resetSearch() {
      this.searchList = {
        productId: 0,
        brand: null,
        productName: "",
        productLine: "",
        productManageUserId: null,
        productLineManangeUserId: null,
        agicode: "",
        isSpecial: 2
      };
    },
    async getAllDDL() {
      const { result: brandList } = await this.$http.get(
        "/Home/GetSelectList?lookup=brand"
      );
      const { result: productName } = await this.$http.get(
        "/Home/GetSelectList?lookup=productName"
      );
      const { result: productLine } = await this.$http.get(
        "/Home/GetSelectList?lookup=productLine"
      );
      const { result: productManager } = await this.$http.get(
        "/Home/GetSelectList?lookup=productMananger"
      );
      const { result: productLineMananger } = await this.$http.get(
        "/Home/GetSelectList?lookup=productLineMananger"
      );
      const { result: agicode } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicode"
      );
      this.brandDDL = brandList;
      this.productNameDDL = productName;
      this.productLineDDL = productLine;
      productManager.forEach(e=>e.value = +(e.value))
      productLineMananger.forEach(e=>e.value = +(e.value))
      this.productManagerDDL = productManager;
      this.productLineManangerDDL = productLineMananger;
      this.agicodeDDL = agicode;
      console.log(this.productManagerDDL);
    },
    async showEditDialog(productId) {
      const { result: res } = await this.$http.fetchData(
        "Product/GetProductList",
        {
          product_id: productId,
          brand: "",
          productName: "",
          productLine: "",
          productManageUserId: null,
          productLineManangeUserId: null,
          agicode: null,
          productIsSpecial:2
        }
      );
      //res.productList[0].productManangerId = res.productList[0].productManangerId.toString();
      //res.productList[0].productlineManangerId = res.productList[0].productlineManangerId.toString();
      const { result: sysAgiMaxVal } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicodeColumnNumber"
      );
      this.editAgiIteratorList = [];
      this.sysAgiMaxVal = +sysAgiMaxVal[0].value;
      for (let i = 0; i <= this.sysAgiMaxVal; i++) {
        this.editAgiIteratorList.push(i + 1);
      }
      var originalAgiList = res.productList[0].agicodeList.length;
      for (let i = 0; i <= this.sysAgiMaxVal + 1 - originalAgiList; i++) {
        res.productList[0].agicodeList.push({
          agicode_id: 0,
          agicodeNo: "",
          productId: productId
        });
        }            
      this.editForm = res.productList[0];
      this.editDialogVisible = true;
    },
    async updateProduct() {
      const { result: res } = await this.$http.fetchData(
        "Product/UpdateProduct",
        {
          productData: this.editForm
        }
      );
      if (res == "success") {
        this.getProductList();
        this.$message.success("修改产品数据成功");
        this.editDialogVisible = false;
      } else {
        this.$message.error("修改产品数据失败");
      }
    },
    async showAddDialog() {
      this.addForm = {
        productId: 0,
        productBrand: null,
        productSize: null,
        productName: null,
        productLine: null,
        productManangerId: this.productManagerDDL[0].value,
        productlineManangerId: this.productLineManangerDDL[0].value,
        agicodeList: [{ agicode_id: 0, agicodeNo: "", productId: 0 }],
        discount: {
          basicDiscount:0,
          fapiaoPrice:0,
          distirbutionDiscount:0,
          cost:0,
          netPrice:0
          },

        otherDiscountList: [{ oDiscountId: 0, oDiscountValue: null }],
        productIsSpecial: false
      };

      const { result: sysAgiMaxVal } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicodeColumnNumber"
      );
      this.sysAgiMaxVal = +sysAgiMaxVal[0].value;
      this.addAgiIteratorList = [];
      this.addForm.agicodeList = [];
      for (let i = 0; i <= this.sysAgiMaxVal; i++) {
        this.addAgiIteratorList.push(i + 1);
        if(i==0){
          this.addForm.agicodeList.push({
          agicode_id: 0,
          agicodeNo: "",
          productId: 0,
          class:'agi'+i
        });}
        else{
          this.addForm.agicodeList.push({
          agicode_id: 0,
          agicodeNo: "",
          productId: 0,
          class:'agisfromSecond'
        });
        }
      }
      const { result: otherDiscount } = await this.$http.get(
        "/Home/GetSelectList?lookup=currentyearotherdiscountheader"
      );
      this.otherDiscountIteratorList = [];
      this.addForm.otherDiscountList = [];
      var isPercentClass = "";
      for (let i = 0; i < otherDiscount.length; i++) {
        this.otherDiscountIteratorList.push({
          oDiscountId: +otherDiscount[i].value,
          oDiscountName: otherDiscount[i].label
        });

        if (otherDiscount[i].label.indexOf("%") > -1) {
          isPercentClass = "iconfont icon-percent";
        } else {
          isPercentClass = "";
        }
        this.addForm.otherDiscountList.push({
          oDiscountId: +otherDiscount[i].value,
          oDiscountValue: 0,
          oDiscountName: otherDiscount[i].label,
          isPercentClass: isPercentClass
        });
      }
      this.addDialogVisible = true;
    },
    async addProduct() {
      this.$refs["addFormRef"].validate(async valid => {
        if (valid) {
          //其它折扣验证
          var otherDiscountValid = true;
          this.addForm.otherDiscountList.forEach(e => {
            const regNum = /^-?\d+\.?\d{0,2}$/; //最多两位小数
            if (!e.oDiscountValue) {
            } else if (!regNum.test(e.oDiscountValue)) {
              otherDiscountValid = false;
            }
          });

          if (!this.addForm.agicodeList[0].agicodeNo) {
            return this.$message.error("请输入产品代码A的值");
          }

          if (otherDiscountValid == false) {
            return this.$message.error("请检查输入值是否合法");
          }
          const { result: res } = await this.$http.fetchData(
            "Product/AddProduct",
            {
              productData: this.addForm
            }
          );
          if (res == "success") {
            this.getProductList();
            this.$message.success("新增产品数据成功");
            this.addDialogVisible = false;
          } else {
            this.$message.error("新增产品数据失败");
          }
        }
      });
    },
    async submitUploadSpecial(param) {
      let file = this.$refs.upload.uploadFiles.pop().raw; //这里获取上传的文件对象
      let formData = new FormData();
      formData.append("file", file);
      const { result: res } = await this.$http.post("/", formData);
      console.log(res);
      if (res == "success") {
        this.importDialogVisible = false;
        return this.$message.success("上传成功");
      } else if (res.errorType) {
        return this.$message.error("上传失败：" + res.errorType);
      } else {
        return this.$message.error("上传失败");
      }
    },
    showImportSpecialDialog() {
      this.importSpecialProductVisible = true;
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    addProductInputChange(){
      let getSum = (total, num) => total + num;
      let otherDiscountValue = [];
                this.addForm.otherDiscountList.forEach(e =>
                    otherDiscountValue.push(+e.oDiscountValue)
                );
      let sumOtherDiscount = otherDiscountValue.reduce(getSum);
                  
      this.addForm.discount.netPrice = this.convertDecimal(  
           this.addForm.discount.fapiaoPrice*(1-this.addForm.discount.distirbutionDiscount/100 - sumOtherDiscount/100)
           );
          
    }
  }
};
</script>






<style lang="less" scoped>
.title {
  color: #aab400;
}
.addBtn {
  margin-top: 17px;
  margin-left: -10px;
}
.el-switch {
  margin-top: 9px;
}
.el-tag {
  background-color: #aab400;
  color: #fff;
  border-color: none;
}
.addForm {
  height: 60vh;
  overflow: auto;
}
.editForm {
  height: 60vh;
  overflow: auto;
}


</style>