<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>铺货折扣影响报表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form ref="searchFormRef">
        <el-row>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>品牌</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchForm.productBrand" filterable placeholder>
                    <el-option
                      v-for="item in brandDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>大区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="areaId">
                  <el-select v-model="searchForm.areaId" filterable placeholder @change="areaChange">
                    <el-option
                      v-for="item in areaDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>小区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="saleGroupId">
                  <el-select v-model="searchForm.saleGroupId" @change="handleSelectChange" filterable placeholder>
                    <el-option
                      v-for="item in sGNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>年份</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item prop="year">
                  <el-select
                    v-model="searchForm.year"
                    filterable
                    placeholder
                    @change="handleSelectChange"
                  >
                    <el-option
                      v-for="item in yearDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
              <el-row>
                  <el-col :span="7">
                      <el-form-item>
                          <el-button type="primary" @click="search">查询</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item>
                          <el-button type="primary" @click="resetSearch">重置</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item>
                          <el-button type="primary" @click="exportReport">实时导出</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item>
                          <el-button type="primary" @click="exportReportByEmail">邮件通知导出</el-button>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>

    <el-card class="result-table" shadow="never" v-show="tableShow">
      <el-table :data="reportList" border stripe height="600">
        <el-table-column prop="productBrand" label="品牌"></el-table-column>
        <el-table-column prop="areaName" label="大区"></el-table-column>
        <el-table-column prop="saleGroupName" label="小区"></el-table-column>
        <el-table-column prop="statisticsDimension" label="统计维度"></el-table-column>
        <el-table-column :formatter='keepDigitals' label="预测铺货折扣">
            <template v-slot="scope">
              {{scope.row.forecastDiscountPercent|thousandth}}
            </template>              
        </el-table-column>
        <el-table-column label="预测销量">
            <template v-slot="scope">
              {{scope.row.forecastSales|thousandth}}
            </template>     
        </el-table-column>
        <el-table-column  label="预测净销量">
            <template v-slot="scope">
              {{scope.row.forecastNetSales|thousandth}}
            </template>     
        </el-table-column>
        <el-table-column  label="预测利润">
            <template v-slot="scope">
              {{scope.row.forecastProfit|thousandth}}
            </template>  
        </el-table-column>
        <el-table-column :formatter='keepDigitals' label="预算铺货折扣">
            <template v-slot="scope">
              {{scope.row.budgetDiscountPercent|thousandth}}
            </template>  
        </el-table-column>
        <el-table-column  label="预算销量">
            <template v-slot="scope">
              {{scope.row.budgetSales|thousandth}}
            </template>           
        </el-table-column>
        <el-table-column  label="预算净销售">
            <template v-slot="scope">
              {{scope.row.budgetNetSales|thousandth}}
            </template>           
        </el-table-column>
        <el-table-column  label="预算利润">
            <template v-slot="scope">
              {{scope.row.budgetProfit|thousandth}}
            </template>            
        </el-table-column>
        <el-table-column  label="净销售vs预算">
            <template v-slot="scope">
              {{scope.row.netSalesVS|thousandth}}
            </template>              
        </el-table-column>
        <el-table-column  label="利润vs预算">
            <template v-slot="scope">
              {{scope.row.profitVS|thousandth}}
            </template>            
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      areaDDL: [],
      sGNameDDL: [],
      brandDDL: [],
      yearDDL: [],
      searchForm: {
        productBrand: "",
        areaId: null,
        saleGroupId: null,
        year: null
      },
      reportList: [],
      tableShow: false,
      downloadFileName: null,
      currentYear: null
    };
  },
  created() {
    this.getDDL();
    this.getCurrentDate();
  },
  methods: {
    async getDDL() {
      const { result: brandList } = await this.$http.get(
        "/Home/GetSelectList?lookup=brand"
      );
      const { result: area } = await this.$http.get(
        "/Home/GetSelectList?lookup=userArea"
      );
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: year } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      this.areaDDL = area;
      this.brandDDL = brandList;
      this.sGNameDDL = saleGroup;
      this.yearDDL = year;
    },
    async resetSearch() {
      this.searchForm = {
        productBrand: "",
        areaId: null,
        saleGroupId: null,
        year: this.currentYear
      };
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      this.sGNameDDL = saleGroup;     
      this.tableShow = false;
    },
    async search() {
      const { result: res } = await this.$http.fetchData(
        "/Forecast/GetDiscountReport",
        {
          productBrand: this.searchForm.productBrand,
          areaId: this.searchForm.areaId,
          saleGroupId: this.searchForm.saleGroupId,
          year: this.searchForm.year
        }
      );
      this.reportList = res.result;
      this.tableShow = true;
    },
    async exportReport() {
      const { result: fileName } = await this.$http.fetchData(
        "/Forecast/GetDiscountReport",
        {
          productBrand: this.searchForm.productBrand,
          areaId: this.searchForm.areaId,
          saleGroupId: this.searchForm.saleGroupId,
          year: this.searchForm.year,
            isExport: true,
            isStored: false
        }
        );
        if (fileName == "success") {
           // return this.$message.success("导出请求已被记录，后面会以邮件形式通知下载。");
            return this.$message.success("导出成功");
        } else {
            this.downloadFileName = fileName;

            var todayDate = new Date();
            const res = await this.$http.downLoad(
              `/Home/Download?fileName=${this.downloadFileName}`
            );
            var blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            var objectUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.setAttribute("style", "display:none");
            a.setAttribute("href", objectUrl);
            a.setAttribute(
              "download",
              `折扣影响报表_${todayDate.getFullYear()}${todayDate.getMonth() +
                1}${todayDate.getDate()}.xlsx`
            );
            a.click();
            URL.revokeObjectURL(objectUrl);
        }
      },
      async exportReportByEmail() {
          const { result: fileName } = await this.$http.fetchData(
              "/Forecast/GetDiscountReport",
              {
                  productBrand: this.searchForm.productBrand,
                  areaId: this.searchForm.areaId,
                  saleGroupId: this.searchForm.saleGroupId,
                  year: this.searchForm.year,
                  isExport: true,
                  isStored: true
              }
          );
          if (fileName == "success") {
              return this.$message.success("导出请求已被记录，后面会以邮件形式通知下载。");
              //return this.$message.success("导出成功");
          } else {
              return this.$message.error("导出失败");
          }
      },
    getCurrentDate() {
      let date = new Date();
      this.currentYear = date.getFullYear(); //获取完整的年份(4位)
      this.searchForm.year = this.currentYear;
    },
    handleSelectChange() {
      this.$forceUpdate();

    },
    keepDigitals(row, column) {
          if (isNaN(row[column.property]) || !row[column.property])
              return row[column.property];
          else
              return (Math.round(row[column.property] * 100) / 100).toFixed(2)
    },
    async areaChange(){
      if(!!this.searchForm.areaId)
      {
       this.searchForm.saleGroupId = null
       const { result: sglist } = await this.$http.get(
        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchForm.areaId}`
       );
      this.sGNameDDL = sglist;
      }
    }
  }
};
</script>