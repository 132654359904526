import { render, staticRenderFns } from "./Area.vue?vue&type=template&id=6fe86910&scoped=true&"
import script from "./Area.vue?vue&type=script&lang=js&"
export * from "./Area.vue?vue&type=script&lang=js&"
import style0 from "./Area.vue?vue&type=style&index=0&id=6fe86910&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe86910",
  null
  
)

export default component.exports