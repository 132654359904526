<template>
    <div class="errBox">
        <!--<span class="emo">:( </span>-->
        <span  v-if="errorCode == 'NOT_FOUND_USER'">数据库未找到用户</span>
        <span  v-else-if="errorCode == 'INVALID_USER'">用户验证失败</span>
        <span v-else-if="errorCode == 'SSO_EXCEPTION'">您好，目前您没有铺货折扣管理系统的相关权限，无法查看系统页面。相关系统权限问题，请咨询系统管理员 <a href="mailto:cecily.chen@syngenta.com"></a>Cecily Chen。</span>
        <span  v-else></span> 
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errorCode: ""
            };
        },
        created() {
            this.getErrorCode();
        },
        methods: {
            getQueryVariable(variable) {
                var q = window.location.href.split('?');
                if (q.length > 1) {
                    var query = q[1];
                    var vars = query.split("&");
                    for (var i = 0; i < vars.length; i++) {
                        var pair = vars[i].split("=");
                        if (pair[0] === variable) { return pair[1]; }
                    }
                }
                return '';
            },
            getErrorCode() {
                this.errorCode = this.getQueryVariable('code').toUpperCase();
            }
        }
    };
</script>

<style lang="less">
.errBox{
   width: 600px;
   height: 300px;
   margin:0 auto;
   position: relative;
   top: 50%;
   margin-top: -150px; 
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 2em;
}
.emo{
    margin-right: 20px;
}


</style>
 