<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>大小区管理</el-breadcrumb-item>
        </el-breadcrumb>
   
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
         <!-- 大区面板 -->
        <el-card class="search-item" shadow="never">
        <span>搜索条件</span>
        </el-card>
        <el-tab-pane label="大区管理" name="area">
        <el-card class="search-form" shadow="never">
            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchList">
                <el-form-item label="大区名">
                    <el-input v-model="searchList.areaName" placeholder="请输入大区名"></el-input>
                </el-form-item>
                <el-form-item style="right:auto">
                    <el-button type="primary" @click="getAreaList" >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="search-item" shadow="never">
            <span>查询结果</span>
        </el-card>
        <el-card class="result-table" shadow="never">
            <el-row  :gutter="20">
              <el-col :span="20"><h3 class="title">大区列表</h3></el-col>
              <el-col :span="4"><el-button class="btn addBtn" type="primary" @click="areaAddDialogVisible = true" >新增</el-button></el-col>
            </el-row>
            
            <!-- 大区列表 -->
            <el-table   :data="areaList" border stripe>
               <el-table-column prop="areaId" label="大区ID" v-if="areaIDShow"></el-table-column>
               <el-table-column prop="areaName" label="大区名"></el-table-column>
               <el-table-column prop="areaActiveYear" label="起效时间"></el-table-column>
               <el-table-column prop="areaIsActive" label="是否有效">
                     <template slot-scope="scope">
                        <span  v-if="scope.row.areaIsActive === true">是</span>
                        <span  v-else>否</span>
                    </template>
               </el-table-column>
               <el-table-column prop="areaLastModifyTime" label="最后修改时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.areaLastModifyTime|dateFormat}}</span>
                    </template>
               </el-table-column>
               <el-table-column prop="areaModifyUserName" label="最后修改人"></el-table-column> 
               <el-table-column width="80px" label="操作">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="修改大区" :enterable="false" placement="top">
                            <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="showAreaEditDialog(scope.row.areaId)" ></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            
            <!-- 修改大区的对话框 -->
            <el-dialog title="修改大区信息"
                       :visible.sync="areaEditDialogVisible"
                       width="40%"
                       :close-on-click-modal='false'
                      >
                <el-form :model="editAreaForm"                    
                         label-width="100px"
                         ref="editAreaFormRef">
                    <el-form-item label="大区名">
                        <el-input v-model="editAreaForm.areaName"></el-input>
                    </el-form-item>
                    <el-form-item label="起效时间">
                        <el-input v-model="editAreaForm.areaActiveYear"></el-input>
                    </el-form-item>
                    <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="editAreaForm.areaIsActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="areaEditDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updateArea">保存</el-button>
                </span>
            </el-dialog>
            <!-- 新增大区的对话框 -->
             <el-dialog title="新增大区信息"
                       :visible.sync="areaAddDialogVisible"
                       width="40%"
                       @close="areaAddDialogClosed"
                       :close-on-click-modal='false'
                       >
                <el-form :model="addAreaForm"                    
                         label-width="100px"
                         ref="addAreaFormRef"
                         :rules="addAreaFormRules">
                    <el-form-item prop="areaName" label="大区名">
                        <el-input v-model="addAreaForm.areaName"></el-input>
                    </el-form-item>
                    <el-form-item prop="areaActiveYear" label="起效时间">
                        <el-input v-model="addAreaForm.areaActiveYear"></el-input>
                    </el-form-item>
                    <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="addAreaForm.areaIsActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="areaAddDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addArea">保存</el-button>
                </span>
            </el-dialog>
        </el-card>
        </el-tab-pane>

         <!-- 小区面板 -->

        <el-tab-pane label="小区管理" name="saleGroup">
        <el-card class="search-form" shadow="never">
            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchSGList">
                <el-form-item label="小区名">
                    <el-input v-model="searchSGList.saleGroupName" placeholder="请输入小区名"></el-input>
                </el-form-item>
                <el-form-item style="right:auto">
                    <el-button type="primary" @click="getSaleGroupList" >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="search-item" shadow="never">
            <span>查询结果</span>
        </el-card>
        <el-card class="result-table" shadow="never">
            <el-row  :gutter="20">
              <el-col :span="20"><h3 class="title">小区列表</h3></el-col>
              <el-col :span="4"><el-button class="btn addBtn" type="primary" @click="showSgAddDialog" >新增</el-button></el-col>
            </el-row>
            
            <!-- 小区列表 -->
            <el-table  :data="saleGroupList"  border stripe>
               <el-table-column prop="salegroupId" label="小区ID" v-if="saleGroupIDShow"></el-table-column>
               <el-table-column prop="salegroupareaName" label="大区名"></el-table-column>
               <el-table-column prop="salegroupName" label="小区名"></el-table-column>
               <el-table-column prop="salegroupAssistantName" label="小区助理"></el-table-column>
               <el-table-column prop="salegroupAssistantEmail" label="邮箱"></el-table-column>
               <el-table-column prop="salegroupManangeName" label="小区经理"></el-table-column>
               <el-table-column prop="salegroupManangeEmail" label="邮箱"></el-table-column>
               <el-table-column prop="salegroupisActive" label="是否有效">
                     <template slot-scope="scope">
                        <span  v-if="scope.row.salegroupisActive === true">是</span>
                        <span  v-else>否</span>
                    </template>
               </el-table-column>
               <el-table-column prop="salegroupactiveYear" label="起效时间"></el-table-column>
               <el-table-column prop="lastModifyTime" label="最后修改时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.lastModifyTime|dateFormat}}</span>
                    </template>
               </el-table-column>
                 <el-table-column prop="lastModifyerUserName" label="最后修改人">
               </el-table-column>
               <el-table-column width="80px" label="操作">
                    <template  slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="修改" :enterable="false" placement="top">
                            <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="showSgEditDialog(scope.row.salegroupId)"  ></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

             <!-- 修改小区的对话框 -->
            <el-dialog title="修改小区信息"
                       :visible.sync="sgEditDialogVisible"
                       width="40%"
                       :close-on-click-modal='false'
                      >
                <el-form :model="editSgForm"                    
                         label-width="100px"
                         ref="editSgFormRef">
                    <el-form-item label="大区名">
                          <el-select v-model="editSgForm.salegroupareaId" placeholder="请选择大区">
                          <el-option
                           v-for="item in areaNameList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="小区名">
                    <el-input v-model="editSgForm.salegroupName" placeholder="请输入小区名"></el-input>
                    </el-form-item>
                    <el-form-item label="小区经理/邮箱">
                    <el-row>
                        <el-col :span="10"><el-input v-model="sgManagerName" placeholder="经理名字"></el-input></el-col>
                        <el-col :span="10"><el-input v-model="sgManagerEmail" placeholder="经理邮箱"></el-input></el-col>
                        <el-col :span="4"><el-button @click="addSgManager" type="primary" >添加</el-button></el-col>
                    </el-row>                 
                    </el-form-item>
                    <el-form-item>
                    <el-tag closable @close="removeManager(item.salegroupManangeEmail)" v-for="item in editSgForm.salegroupManangeList" :key="item.salegroupManangeEmail">{{item.salegroupManangeName+'——'+item.salegroupManangeEmail}}</el-tag>
                    </el-form-item>
                    <el-form-item label="小区助理/邮箱">
                    <el-row>
                        <el-col :span="10"><el-input v-model="sgAssistName" placeholder="助理名字"></el-input></el-col>
                        <el-col :span="10"><el-input v-model="sgAssistEmail" placeholder="助理邮箱"></el-input></el-col>
                        <el-col :span="4"><el-button @click="addSgAssist" type="primary" >添加</el-button></el-col>
                    </el-row>                 
                    </el-form-item>
                    <el-form-item>
                    <el-tag closable @close="removeAssist(item.salegroupAssistantEmail)" v-for="item in editSgForm.salegroupAssistantList" :key="item.salegroupAssistantEmail">{{item.salegroupAssistantName+'——'+item.salegroupAssistantEmail}}</el-tag>
                    </el-form-item>
                    <el-form-item label="起效时间">
                          <el-input v-model="editSgForm.salegroupactiveYear" placeholder="请输入起效时间"></el-input>
                    </el-form-item>
                     <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="editSgForm.salegroupisActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>


                

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="sgEditDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updateSaleGroup">保存</el-button>
                </span>
            </el-dialog>

            <!-- 添加小区的对话框 -->
            <el-dialog title="添加小区信息"
                       :visible.sync="sgAddDialogVisible"
                       width="40%"
                       :close-on-click-modal='false'
                      >
                <el-form :model="addSgForm"                    
                         label-width="100px"
                         ref="addSgFormRef"
                         :rules="addSgFormRules">
                    <el-form-item label="大区名">
                          <el-select v-model="addSgForm.salegroupareaId" @change="addSgAreaChange" placeholder="请选择大区">
                          <el-option
                           v-for="item in areaNameList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="小区名">
                    <el-input v-model="addSgForm.salegroupName" placeholder="请输入小区名"></el-input>
                    </el-form-item>
                    <el-form-item label="小区经理/邮箱">
                    <el-row>
                        <el-col :span="10"><el-input v-model="sgManagerNameNew" placeholder="经理名字"></el-input></el-col>
                        <el-col :span="10"><el-input v-model="sgManagerEmailNew" placeholder="经理邮箱"></el-input></el-col>
                        <el-col :span="4"><el-button @click="addSgManagerNew" type="primary" >添加</el-button></el-col>
                    </el-row>                 
                    </el-form-item>
                    <el-form-item>
                    <el-tag closable @close="removeManagerNew(item.salegroupManangeEmail)" v-for="item in addSgForm.salegroupManangeList" :key="item.salegroupManangeEmail">{{item.salegroupManangeName+'——'+item.salegroupManangeEmail}}</el-tag>
                    </el-form-item>
                    <el-form-item label="小区助理/邮箱">
                    <el-row>
                        <el-col :span="10"><el-input v-model="sgAssistNameNew" placeholder="助理名字"></el-input></el-col>
                        <el-col :span="10"><el-input v-model="sgAssistEmailNew" placeholder="助理邮箱"></el-input></el-col>
                        <el-col :span="4"><el-button @click="addSgAssistNew" type="primary" >添加</el-button></el-col>
                    </el-row>                 
                    </el-form-item>
                    <el-form-item>
                    <el-tag closable @close="removeAssistNew(item.salegroupAssistantEmail)" v-for="item in addSgForm.salegroupAssistantList" :key="item.salegroupAssistantEmail">{{item.salegroupAssistantName+'——'+item.salegroupAssistantEmail}}</el-tag>
                    </el-form-item>
                    <el-form-item label="起效时间" prop="salegroupactiveYear">
                          <el-input v-model="addSgForm.salegroupactiveYear" placeholder="请输入起效时间"></el-input>
                    </el-form-item>
                     <el-form-item label="是否有效">
                        <el-switch style="display: block"
                                   v-model="addSgForm.salegroupisActive"
                                   active-color="#AAB400"
                                   active-text="是"
                                   inactive-text="否">
                        </el-switch>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="sgAddDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addNewSaleGroup">保存</el-button>
                </span>
            </el-dialog>
             
        </el-card>
        </el-tab-pane>
        </el-tabs>
    </div>
</template>


<script>
  export default {
    data() {
      var checkActiveYear = (rule, value, cb) => {
      var date=new Date;
      var year=date.getFullYear();
      if (value>year) {
        return cb();
      }
      cb(new Error("请输入明年或以后的年份！"));
           };


      return {
         searchList: {
         areaName: '',
         areaId:0
         }, 
         areaList:[],
         activeName:'area',
         areaIDShow:false,
         areaEditDialogVisible:false,
         editAreaForm:{areaId:0,areaName:"",areaActiveYear:"",areaIsActive:false},
         userId:0,
         userName:"",
         areaAddDialogVisible:false,
         addAreaForm:{areaId:0,areaName:"",areaActiveYear:"",areaIsActive:true},
         addAreaFormRules:{
              areaActiveYear:[
                  { required: true, message: "请输入起效时间", trigger: "change" },
                  { validator: checkActiveYear, trigger: "change" }
              ],
              areaName:[{ required: true, message: "请输入大区名", trigger: "blur" },]
         },
         addSgFormRules:{
              salegroupactiveYear:[
                  { required: true, message: "请输入起效时间", trigger: "blur" },
                  { validator: this.checkSGActiveYear, trigger: "change" }
              ]
         },

         searchSGList:{
         saleGroupName:'',
         sgId:0
         },
         saleGroupIDShow:false,
         saleGroupList:[],
         sgEditDialogVisible:false,
         editSgForm:{},
         areaNameList:[],
         sgManagerName:"",
         sgManagerEmail:"",
         sgAssistName:"",
         sgAssistEmail:"",
         sgManagerNameNew:"",
         sgManagerEmailNew:"",
         sgAssistNameNew:"",
         sgAssistEmailNew:"",
         sgAddDialogVisible:false,
         addSgForm:{salegroupId:0,salegroupName:'',salegroupactiveYear:'',
         salegroupareaId:0,salegroupareaName:'',salegroupisActive:true,lastModifyerUserName:"",lastModifyerUserId:0,salegroupAssistantList:[],salegroupManangeList:[]}, 
         selectAreaActiveYear:0
     }
    },
    created(){
      this.getAreaList()
      this.getUserInfo()
      this.getSaleGroupList()
    },
    methods: {
         handleTabClick(){
           
         },
         async getUserInfo()
         {
          const {result:res} = await this.$http.get('Home/GetUserInfo')
          if(!!res[0].userId)
          {
          this.userId = res[0].userId
          this.userName = res[0].userName
          }
         },
         async getAreaList()
         {
             const {result:res} = await this.$http.fetchData('area/GetAreaList',{areaName:this.searchList.areaName,areaId:this.searchList.areaId})
             this.areaList = res
             if(this.areaList.length==0)
                {
                    return this.$message.warning("没有对应条件的大区数据");
                }
         },
         async showAreaEditDialog(areaId)
         {
             const {result:res} = await this.$http.fetchData('area/GetAreaList',{areaName:"",areaId:areaId})
             this.editAreaForm = res[0]
             this.areaEditDialogVisible = true
         },
         areaAddDialogClosed(){
             this.$refs.addAreaFormRef.resetFields()
         },
         async updateArea()
         {
             const {result:res} = await this.$http.fetchData('area/UpdateArea',
             {areaId:this.editAreaForm.areaId, areaName:this.editAreaForm.areaName, 
             areaActiveYear:this.editAreaForm.areaActiveYear,areaIsActive:this.editAreaForm.areaIsActive, userId:this.userId})
             if(res==="success")
            {
                this.getAreaList()
                this.areaEditDialogVisible = false
                return this.$message.success("修改大区信息成功");
            }
           else{
             return this.$message.error("修改大区信息失败");
               }
         },
         async addArea()
         {
             var date=new Date;
             var year=date.getFullYear();
             this.$refs["addAreaFormRef"].validate(async (valid) => {
             if (valid) {
                 const {result:res} = await this.$http.fetchData('area/UpdateArea',
                {areaId:this.addAreaForm.areaId, areaName:this.addAreaForm.areaName, 
                areaActiveYear:this.addAreaForm.areaActiveYear,areaIsActive:this.addAreaForm.areaIsActive, userId:this.userId})
                if(res==="success")
                {
                this.getAreaList()
                this.areaAddDialogVisible = false
                return this.$message.success("新增大区信息成功");
                }
                else{return this.$message.error("新增大区信息失败");  }

             } 
             else {
              return false;
              }
             });
             
         },
         async getSaleGroupList()
         {
             const {result:res} = await this.$http.fetchData('salegroup/GetSaleGroupList',{sgName:this.searchSGList.saleGroupName,sgId:this.searchSGList.sgId})
             this.saleGroupList = res
             if(this.saleGroupList.length==0)
                {
                    return this.$message.warning("没有对应条件的小区数据");
                }
         },
          async showSgEditDialog(sgId)
         {
             
             const {result:res} = await this.$http.fetchData('salegroup/GetSaleGroupList',{sgName:"",sgId:sgId})
             const {result:userAreaList} = await this.$http.get('/Home/GetSelectList?lookup=userArea')
             res[0].salegroupareaId = res[0].salegroupareaId.toString()
             this.editSgForm = res[0]
             this.areaNameList = userAreaList
             const {result:areaList} = await this.$http.fetchData('area/GetAreaList',{areaName:"",areaId:this.editSgForm.salegroupareaId})
             this.selectAreaActiveYear = areaList[0].areaActiveYear
             this.sgEditDialogVisible = true
         },
          async showSgAddDialog()
         {
             this.addSgForm = {salegroupId:0,salegroupName:'',salegroupactiveYear:'',
                               salegroupareaId:0,salegroupareaName:'',salegroupisActive:true,lastModifyerUserName:"",lastModifyerUserId:0,salegroupAssistantList:[],salegroupManangeList:[]}
             const {result:userAreaList} = await this.$http.get('/Home/GetSelectList?lookup=userArea')
             this.areaNameList = userAreaList
             this.addSgForm.salegroupareaId = userAreaList[0].value
             const {result:areaList} = await this.$http.fetchData('area/GetAreaList',{areaName:"",areaId:this.addSgForm.salegroupareaId})
             this.selectAreaActiveYear = areaList[0].areaActiveYear
             this.sgAddDialogVisible = true
         },
         addSgManager()
         {
             if(!!this.sgManagerEmail||!!this.sgManagerName)
             {
             this.editSgForm.salegroupManangeList.push({salegroupManangeId:0,salegroupId:this.editSgForm.salegroupId,salegroupManangeName:this.sgManagerName,salegroupManangeEmail:this.sgManagerEmail})
             this.sgManagerEmail=""
             this.sgManagerName=""
             }
         },
        addSgManagerNew()
         {
             if(!!this.sgManagerEmailNew||!!this.sgManagerNameNew)
             {
             this.addSgForm.salegroupManangeList.push({salegroupManangeId:0,salegroupId:this.addSgForm.salegroupId,salegroupManangeName:this.sgManagerNameNew,salegroupManangeEmail:this.sgManagerEmailNew})
             this.sgManagerEmailNew=""
             this.sgManagerNameNew=""
             }
         },
         addSgAssist(){
             if(!!this.sgAssistName||!!this.sgAssistEmail){
              this.editSgForm.salegroupAssistantList.push({salegroupAssistantId:0,salegroupId:this.editSgForm.salegroupId,salegroupAssistantName:this.sgAssistName,salegroupAssistantEmail:this.sgAssistEmail})
              this.sgAssistName=""
              this.sgAssistEmail = ""
            }
         },
         addSgAssistNew(){
             if(!!this.sgAssistNameNew||!!this.sgAssistEmailNew){
              this.addSgForm.salegroupAssistantList.push({salegroupAssistantId:0,salegroupId:this.editSgForm.salegroupId,salegroupAssistantName:this.sgAssistNameNew,salegroupAssistantEmail:this.sgAssistEmailNew})
              this.sgAssistNameNew=""
              this.sgAssistEmailNew = ""
            }
         },
         removeManager(managerEmail){
              this.editSgForm.salegroupManangeList.splice(this.editSgForm.salegroupManangeList.findIndex(e=>e.salegroupManangeEmail==managerEmail),1)
         },
         removeAssist(assistEmail)
         {
             this.editSgForm.salegroupAssistantList.splice(this.editSgForm.salegroupAssistantList.findIndex(e=>e.salegroupAssistantEmail==assistEmail),1)
         },
         removeManagerNew(managerEmail){
              this.addSgForm.salegroupManangeList.splice(this.addSgForm.salegroupManangeList.findIndex(e=>e.salegroupManangeEmail==managerEmail),1)
         },
         removeAssistNew(assistEmail)
         {
             this.addSgForm.salegroupAssistantList.splice(this.addSgForm.salegroupAssistantList.findIndex(e=>e.salegroupAssistantEmail==assistEmail),1)
         },

         async updateSaleGroup()
         {
            var editSgFormData = this.editSgForm
            editSgFormData.lastModifyerUserId = this.userId
            editSgFormData.lastModifyerUserName = this.userName
            const {result:res} = await this.$http.fetchData('salegroup/UpdateSaleGroup', {data:editSgFormData})
           
            this.getSaleGroupList()
            if(res == "success")
            {
                 this.$message.success("更新小区数据成功");
                 this.sgEditDialogVisible = false
            }
            else{
                 this.$message.error("更新小区数据失败"); 
            }
         },
         async addNewSaleGroup()
         {
             this.$refs["addSgFormRef"].validate(async (valid) => {
             if (valid) {
            this.addSgForm.lastModifyerUserId = this.userId
            this.addSgForm.lastModifyerUserName = this.userName
            const {result:res} = await this.$http.fetchData('salegroup/UpdateSaleGroup', {data:this.addSgForm})
            
            if(res == "success")
            {
                 this.getSaleGroupList()
                 this.$message.success("添加小区数据成功");
                 this.sgAddDialogVisible = false
            }
            else{
                 this.$message.error("添加小区数据失败"); 
            } 
             }
              else {
              return false;
              }
             });
             
         },
         async addSgAreaChange()
         {
                const {result:areaList} = await this.$http.fetchData('area/GetAreaList',{areaName:"",areaId:this.addSgForm.salegroupareaId})
                this.selectAreaActiveYear = areaList[0].areaActiveYear
         },
         checkSGActiveYear(rule, value, cb)
         {
            var year=this.selectAreaActiveYear;
            if (value>=year) { return cb(); }
            cb(new Error("请输入不小于"+year+"年份！"));
         }
         
    }
  }
</script>






<style lang="less" scoped>
  .title{
      color:#AAB400;
  }
  .addBtn{
      margin-top:17px;
  }
  .el-switch{
    margin-top:9px;
  }
  .el-tag
  {
    background-color:#AAB400;
    color:#fff;
    border-color:none;
  }
  
  
</style>